import React from 'react'
import Layout from '../layout/Layout'
import Main from '../about/Main'
import Swipers from '../home/Swiper/Swipers'

const About = () => {
  return (
    <>
    <Layout>
      <Swipers />
        <Main/>
    </Layout>
    </>
  )
}

export default About