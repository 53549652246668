import * as Yup from "yup";

export const initialValuesOraganizer = {
  mailingAddress: "",
  city1: "",
  province: "",
  country1: "",
  postalCode: "",
  phoneNumber: "",
  firstNationName: "",
  password: "",
};

export const validationSchemaOraganizer = Yup.object({
  mailingAddress: Yup.string().required("Mailing Address is required"),
  city1: Yup.string().required("City is required"),
  province: Yup.string().required("Province is required"),
  country1: Yup.string().required("Country is required"),
  postalCode: Yup.string().required("Postal Code is required"),
  phoneNumber: Yup.string().required("Phone Number is required"),
  firstNationName: Yup.string().required("First Nation Name is required"),
  password: Yup.string().required("Password is required"),
});







