/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";

const MainContent = () => {
  return (
    <>
      <div style={{ backgroundImage: 'url("/images/banner1.png")' }}>
        <div className="google-maps">
          <div id="map" className="map contact-map" />
        </div>
        <div id="page-contents">
          <div className="container">
            <div className="row">
              <div className="col-md-10 col-md-offset-1">
                <div className="contact-us">
                  <div className="row">
                    <div className="col-md-8 col-sm-7">
                      <h4 className="grey fw-bold">Leave a Message</h4>
                      <form className="contact-form">
                        <div className="form-group">
                          <i className="icon ion-person" />
                          <input
                            id="contact-name"
                            type="text"
                            name="name"
                            className="form-control"
                            placeholder="Enter your name *"
                            required="required"
                            data-error="Name is required."
                          />
                        </div>
                        <div className="form-group">
                          <i className="icon ion-email" />
                          <input
                            id="contact-email"
                            type="text"
                            name="email"
                            className="form-control"
                            placeholder="Enter your email *"
                            required="required"
                            data-error="Email is required."
                          />
                        </div>
                        <div className="form-group">
                          <i className="icon ion-android-call" />
                          <input
                            id="contact-phone"
                            type="text"
                            name="phone"
                            className="form-control"
                            placeholder="Enter your phone *"
                            required="required"
                            data-error="Phone is required."
                          />
                        </div>
                        <div className="form-group">
                          <textarea
                            id="form-message"
                            name="message"
                            className="form-control"
                            placeholder="Leave a message for us *"
                            rows={4}
                            required="required"
                            data-error="Please,leave us a message."
                            defaultValue={""}
                          />
                        </div>
                      </form>
                      <button
                        className="btn-primary"
                        style={{ background: "#04283a" }}
                      >
                        Send a Message
                      </button>
                    </div>
                    <div className="col-md-4 col-sm-5">
                      <h4 className="grey fw-bold">Contact us</h4>
                      <div className="reach">
                        <span className="phone-icon">
                          <i className="icon ion-android-call" />
                        </span>
                        <p>- 604.690.3561</p>
                      </div>
                      <div className="reach">
                        <span className="phone-icon">
                          <i className="icon ion-email" />
                        </span>
                        <p>hello@indstree.com</p>
                      </div>
                      <div className="reach">
                        <span className="phone-icon">
                          <i className="icon ion-ios-location" />
                        </span>
                        <p>
                          Suite 250 - #939 997 Seymour St. Vancouver, BC, Canada
                          V6B 3M1
                        </p>
                      </div>
                      <ul className="list-inline social-icons">
                        <li>
                          <a
                            href="https://www.linkedin.com/company/indstree-research-data-inc/about/"
                            target="_blank"
                            className="d-flex"
                          >
                            <i className="icon ion-social-linkedin" style={{fontSize:'25px'}} />
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default MainContent;
