import React from 'react'

const NewSection = () => {
  return (
    <>
    <div className="container-fluid new-section-container">
        <div className="row">
            <div className="col-lg-6 col-sm-12">
            <div className='new-section'>
                <img src="/images/industree.jpg" alt="" />
            </div>
            </div>
            <div className="col-lg-6 col-sm-12">
                <div className='new-section1'>
                <h1>Research and data solutions that work.</h1>
                <p>With in-depth, informative and objective research, Indstree is a critical source for industry intelligence that leads to better business decisions and better results.</p>
                <button>About</button>
                </div>
            </div>
        </div>
    </div>
    <div className="container-fluid bg-new">
        <div className="row">
            <div className="col-lg-8">
                <h2>Meet Indstree</h2>
                <h1>A growing powerhouse of accurate, timely, and actionable industry intelligence.</h1>
               <button>About</button>
            </div>
        </div>
    </div>
    </>
  )
}

export default NewSection