/* eslint-disable jsx-a11y/img-redundant-alt */
/* eslint-disable eqeqeq */
/* eslint-disable jsx-a11y/anchor-is-valid */
import { useFormik } from "formik";
import { NavLink, useNavigate } from "react-router-dom";
import { initialValuesSubcribe, validationSchemaSubcribe } from "./schema";
import { useEffect, useState } from "react";
import StripeCheckout from "react-stripe-checkout";
import { toast } from "react-toastify";
import NewAxiosInstance from "../../../https/newAxios";
import { Loader } from "../../loader/Loader";
import { BiShow, BiSolidHide } from "react-icons/bi";

function SignupSubcriber() {
  const navigate = useNavigate();
  const [loader, setLoader] = useState(false);
  const [show, setShow] = useState(false);
  const [id, setId] = useState(null);
  const [subcription, setSubcription] = useState([]);
  useEffect(() => {
    NewAxiosInstance.get("/show-subscriptions")
      .then((data) => {
        console.log(data?.data?.data , 'subcription');
        setSubcription(data.data?.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  const { values, errors, handleBlur, handleChange, handleSubmit, touched } =
    useFormik({
      initialValues: initialValuesSubcribe,
      validationSchema: validationSchemaSubcribe,
      onSubmit: (values) => {
        setLoader(true);
        console.log(values, "values");
      },
    });

  const onToken = (token) => {
    console.log(token);
    console.log("id", id);
    handleSubmit();
    setLoader(true);
    const formData = new FormData();
    formData.append("first_name", values.firstname);
    formData.append("last_name", values.lastname);
    formData.append("email", values.email);
    formData.append("password", values.password);
    formData.append("notification_permissison", values.notification);
    formData.append("subscription_level", id?.id);
    formData.append("role", "user");
    formData.append("source", token?.id);
    formData.append("amount", id?.price);
    NewAxiosInstance.post("register", formData).then((response) => {
      console.log(response);
      toast.success(response?.data?.message);
      navigate("/loginsubcriber");
      setLoader(false);
    });
  };

  const handlerClick = () => {
    handleSubmit();
  };

  return (
    <>
      <section
        id="banner1"
        style={{
          height: "200vh",
          backgroundImage: 'url("images/banner1.png")',
        }}
      >
        <div className="container">
          <div className="sign-up-form">
            <div className="w-100" style={{ gap: "20px" }}>
              <div>
                <button
                  type="submit"
                  className="btn-secondary tab"
                  style={{ background: "#336230" }}
                >
                  Subscriber
                </button>
              </div>
            </div>
            <h2 style={{ margin: "30px" }} className="text-white">
              Welcome to Indstree
            </h2>
            <div className="line-divider" />
            <div className="form-wrapper">
              <form onSubmit={handleSubmit}>
                <div className="row flex">
                  <fieldset className="form-group">
                  <label htmlFor="" className="d-flex justify-content-start" style={{marginLeft:'10px' , fontSize:'16px' , fontWeight: 600}}>First Name</label>
                    <input
                      type="firstname"
                      className="form-control"
                      id="firstname"
                      name="firstname"
                      placeholder="Enter First Name"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.firstname}
                    />
                    {errors.firstname && touched.firstname ? (
                      <span className="text-danger fs-6">
                        {errors.firstname}
                      </span>
                    ) : null}
                  </fieldset>
                  <fieldset className="form-group">
                  <label htmlFor="" className="d-flex justify-content-start" style={{marginLeft:'10px' , fontSize:'16px' , fontWeight: 600}}>Last Name</label>
                    <input
                      type="text"
                      className="form-control"
                      id="lastname"
                      name="lastname"
                      placeholder="Enter Last Name"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.lastname}
                    />
                    {errors.lastname && touched.lastname ? (
                      <span className="text-danger fs-6">
                        {errors.lastname}
                      </span>
                    ) : null}
                  </fieldset>
                </div>
                <div className="row flex">
                  <fieldset className="form-group">
                  <label htmlFor="" className="d-flex justify-content-start" style={{marginLeft:'10px' , fontSize:'16px' , fontWeight: 600}}>Email</label>
                    <input
                      type="email"
                      className="form-control"
                      id="email"
                      name="email"
                      placeholder="Enter Email"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.email}
                    />
                    {errors.email && touched.email ? (
                      <span className="text-danger fs-6">{errors.email}</span>
                    ) : null}
                  </fieldset>
                  <fieldset className="form-group cursor-pointer">
                  <label htmlFor="" className="d-flex justify-content-start" style={{marginLeft:'10px' , fontSize:'16px' , fontWeight: 600}}>Password</label>
                    <input
                      type={show ? "text" : "password"}
                      className="form-control"
                      id="password"
                      name="password"
                      placeholder="Enter a password"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.password}
                    />
                    <div className="eye">
                      {show ? (
                        <BiShow
                          onClick={() => setShow(!show)}
                          color="black"
                          size={20}
                        />
                      ) : (
                        <BiSolidHide
                          onClick={() => setShow(!show)}
                          size={20}
                          color="black"
                        />
                      )}
                    </div>
                    {errors.password && touched.password ? (
                      <span className="text-danger fs-6">
                        {errors.password}
                      </span>
                    ) : null}
                  </fieldset>
                </div>
                <div className="row flex" style={{ alignItems: "baseline" }}>
                  <fieldset className="form-group">
                  <label htmlFor="" className="d-flex justify-content-start" style={{marginLeft:'10px' , fontSize:'16px' , fontWeight: 600}}>Notification</label>
                    <select
                      className="form-control"
                      name="notification"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.notification}
                    >
                      <option selected>Select Notification</option>
                      <option value="Allow">Allow</option>
                      <option value="Later">Later</option>
                    </select>{" "}
                    <br />
                    {errors.notification && touched.notification ? (
                      <span className="text-danger fs-6">
                        {errors.notification}
                      </span>
                    ) : null}
                  </fieldset>
                  <fieldset className="form-group">
                  <label htmlFor="" className="d-flex justify-content-start" style={{marginLeft:'10px' , fontSize:'16px' , fontWeight: 600}}>Subcription</label>
                    <select
                      className="form-control"
                      name="subscriptionlevel"
                      onChange={(e) => {
                        handleChange(e);
                        const selectedId = subcription.find(
                          (item) => item.plan_name === e.target.value
                        );
                        setId(selectedId);
                      }}
                      onBlur={handleBlur}
                      value={values.subscriptionlevel}
                    >
                      <option disabled>Select Plan</option>
                      {subcription?.map((item, index) => {
                        return (
                          <option key={index} value={item.plan_name}>
                            {item.plan_name}
                          </option>
                        );
                      })}
                    </select>

                    <br />
                    {errors.subscriptionlevel && touched.subscriptionlevel ? (
                      <span className="text-danger fs-6">
                        {errors.subscriptionlevel}
                      </span>
                    ) : null}
                  </fieldset>
                </div>
                {values?.firstname && values?.lastname && values?.email && values?.password && values?.notification && values?.subscriptionlevel && id ? (
                  <StripeCheckout
                    token={(token) => onToken(token)}
                    stripeKey="pk_test_51IIZO8Kp3j5Y1UYUhdopZryeRKjlRqEcw4rrDhwELKCRDjRSOJtSXicia6jkN63CyflKvQwej7SqGt1MZ6UwKgfz00ipRMArG9"
                  >
                    <button type="submit" className="btn-secondary">
                      {loader ? <Loader /> : "Sign up"}
                    </button>
                  </StripeCheckout>
                ) : (
                  <button onClick={handlerClick} className="btn-secondary">
                    {loader ? <Loader /> : "Sign up"}
                  </button>
                )}
              </form>
            </div>
            <NavLink to={"/loginsubcriber"}>
              New to Indstree? Sign in account
            </NavLink>
            <img
              className="form-shadow"
              src="images/bottom-shadow.png"
              alt=""
            />
          </div>
        </div>
      </section>
    </>
  );
}

export default SignupSubcriber;
