/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";

const Main = () => {
  return (
    <>
      {/* <div style={{backgroundImage: 'url("/images/banner1.png")'}}> */}
      <div className="container">
        <div className="timeline">
          <div id="page-contents">
            <h1 className="text-70">About</h1>
            <div className="row">
              <div className="col-md-10 mx-auto about-desing">
                <div className="about-profile">
                  <div className="about-content-block">
                    <h4 className="grey fw-bold">
                      <i className="ion-ios-information-outline icon-in-title" />
                      Personal Information
                    </h4>
                    <p className="grey-para">
                      Intelligence. Verified. Indstree Research & Data Inc. is
                      the newest company from the people behind Industry West
                      and Manitoba Inc., established business media sources
                      based in Western Canada.
                    </p>
                  </div>
                  <div className="about-content-block">
                    <h4 className="grey fw-bold">
                      <i className="ion-ios-briefcase-outline icon-in-title" />
                      Work Experiences
                    </h4>
                    <div className="organization">
                      <img
                        src="images/envato.png"
                        alt=""
                        className="pull-left img-org"
                      />
                      <div className="work-info">
                        <h5>Our mission: </h5>
                        <p>
                          <span className="text-grey">
                          We do your homework.
                          </span>
                        </p>
                      </div>
                    </div>
                    <div className="organization">
                      <img
                        src="images/envato.png"
                        alt=""
                        className="pull-left img-org"
                      />
                      <div className="work-info">
                        <h5>Our vision: </h5>
                        <p>
                          <span className="text-grey">
                          Better data for better decisions that lead to better results.
                          </span>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* </div> */}
    </>
  );
};

export default Main;
