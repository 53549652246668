/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
/* eslint-disable jsx-a11y/img-redundant-alt */
/* eslint-disable no-dupe-keys */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect } from "react";
import Layout from "../layout/Layout";
import { useParams, useLocation } from "react-router-dom";
import NewAxiosInstance from "../../https/newAxios";

const ProfileViewDetail = () => {
  const { id } = useParams();
  const [data, setData] = useState()
  const allNewsFeedOne = async () => {
    console.log(id);
    try {
      const data = await NewAxiosInstance.get(
        `organization/profile/view/${id}`
      );
      console.log(data?.data?.data, "view");
      setData(data?.data?.data)
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    allNewsFeedOne();
  }, [])
  return (
    <>
      <Layout>
        <div className="container">
          {/* Timeline================================================= */}
          <div className="timeline">
            <div
              className="timeline-cover"
              style={{
                backgroundImage: `url('${data?.header_photo}')`,
                backgroundColor: "#F8F8F8",
              }}
            >
              {/*Timeline Menu for Large Screens*/}
              <div className="timeline-nav-bar hidden-sm hidden-xs">
                <div className="row">
                  <div className="col-md-3">
                    <div className="profile-info">
                      <img
                        src={data?.logo} // Assuming user.id is the unique identifier for each user
                        alt=""
                        className="img-responsive profile-photo"
                        style={{ backgroundColor: "#F8F8F8" }}
                      />
                      <h3>{data?.first_nation}</h3>
                    </div>
                  </div>
                </div>
              </div>
              {/*Timeline Menu for Large Screens End*/}
              {/*Timeline Menu for Small Screens*/}
              <div className="navbar-mobile hidden-lg hidden-md">
                <div className="profile-info">
                  <img
                    src={data?.logo} // Assuming user.id is the unique identifier for each user
                    alt="logo"
                    className="img-responsive profile-photo"
                  />
                  <h4>{data?.first_nation}</h4>
                </div>
              </div>
              {/*Timeline Menu for Small Screens End*/}
            </div>
            <div id="page-contents">
              <div className="row mt-40">
                <div className="col-md-12">
                  {/* Basic Information
        ================================================= */}
                  <div className="edit-profile-container">
                    <div className="block-title">
                      <h4 className="grey">
                        <i className="icon ion-android-checkmark-circle" />
                        Profile Information
                      </h4>
                      <div className="line" />
                      <p>
                        At vero eos et accusamus et iusto odio dignissimos
                        ducimus qui blanditiis praesentium voluptatum deleniti
                        atque corrupti quos dolores et quas molestias excepturi
                        sint occaecati cupiditate
                      </p>
                      <div className="line" />
                    </div>
                    <div className="edit-block">

                      <form
                        name="basic-info"
                        id="basic-info"
                        className="form-inline"

                      >
                        <div className="row">
                          <div className="form-group col-md-6 col-sm-12">
                            <label htmlFor="firstNationName">
                              First Nation Name
                            </label>
                            <input
                              disabled
                              id="firstNationName"
                              className="form-control border-none input-group-lg"
                              type="text"
                              name="firstNationName"
                              title="Enter firstNationName"
                              placeholder="First Nation Name"
                              value={data?.first_nation}
                            />

                          </div>
                          <div className="form-group col-md-6 col-sm-12">
                            <label htmlFor="website" className="">
                              Website URL
                            </label>
                            <input
                              disabled
                              id="website"
                              className="form-control border-none input-group-lg"
                              type="text"
                              name="website"
                              title="Enter Website URL"
                              placeholder="Website URL"
                              value={data?.website_url}
                            />

                          </div>
                        </div>
                        <div className="row">
                          <div className="form-group col-md-6 col-sm-12">
                            <label htmlFor="Mailing Address">
                              Mailing Address
                            </label>
                            <input
                              disabled
                              id="Mailing Address"
                              className="form-control border-none input-group-lg"
                              type="email"
                              name="mailingAddress"
                              title="Enter Mailing Address"
                              placeholder="Mailing Address"
                              value={data?.mailing_address}
                            />

                          </div>
                          <div className="form-group col-md-6 col-sm-12">
                            <label htmlFor="city1" className="">
                              City
                            </label>
                            <input
                              disabled
                              id="city1"
                              className="form-control border-none input-group-lg"
                              type="text"
                              name="city1"
                              title="Enter City"
                              placeholder="City"
                              value={data?.city}
                            />

                          </div>
                        </div>
                        <div className="row">
                          <div className="form-group col-md-6 col-sm-12">
                            <label htmlFor="Province">Province </label>
                            <input
                              disabled
                              id="province"
                              className="form-control border-none input-group-lg"
                              type="text"
                              name="province"
                              title="Enter Province"
                              placeholder="Province"
                              value={data?.province}
                            />

                          </div>
                          <div className="form-group col-md-6 col-sm-12">
                            <label htmlFor="postalCode" className="">
                              Postal Code
                            </label>
                            <input
                              disabled
                              id="postalCode"
                              className="form-control border-none input-group-lg"
                              type="text"
                              name="postalCode"
                              title="Enter Postal Code"
                              placeholder="Postal Code"
                              value={data?.postal_code}
                            />

                          </div>
                        </div>
                        <div className="row">
                          <div className="form-group col-md-6 col-sm-12">
                            <label htmlFor="country1">Country </label>
                            <input
                              disabled
                              id="country1"
                              className="form-control border-none input-group-lg"
                              type="text"
                              name="country1"
                              title="Enter Country "
                              placeholder="Country "
                              value={data?.country}
                            />

                          </div>
                          <div className="form-group col-md-6 col-sm-12">
                            <label htmlFor="phoneNumber" className="">
                              Phone Number
                            </label>
                            <input
                              disabled
                              id="phoneNumber"
                              className="form-control border-none input-group-lg"
                              type="number"
                              name="phoneNumber"
                              title="Enter Phone Number"
                              placeholder="Phone Number"
                              value={data?.phone_no}
                            />

                          </div>
                        </div>
                        <div className="row">
                          <div className="form-group col-md-6 col-sm-12">
                            <label htmlFor="treaty">Treaty </label>
                            <input
                              disabled
                              id="treaty"
                              className="form-control border-none input-group-lg"
                              type="number"
                              name="treaty"
                              title="Enter Treaty "
                              placeholder="Treaty"
                              value={data?.treaty}
                            />

                          </div>
                          <div className="form-group col-md-6 col-sm-12">
                            <label htmlFor="tribalCouncil" className="">
                              Tribal
                            </label>
                            <input
                              disabled
                              id="tribalCouncil"
                              className="form-control border-none input-group-lg"
                              type="text"
                              name="tribalCouncil"
                              title="Enter Tribal "
                              placeholder="Tribal "
                              value={data?.tribal_council}
                            />

                          </div>
                        </div>
                        <div className="row">
                          <div className="form-group col-md-6 col-sm-12">
                            <label htmlFor="physicalLocation">
                              Physical Location
                            </label>
                            <input
                              disabled
                              id="physicalLocation"
                              className="form-control border-none input-group-lg"
                              type="text"
                              name="physicalLocation"
                              title="Enter Physical Location"
                              placeholder="Physical Location"
                              value={data?.physical_location}
                            />

                          </div>
                          <div className="form-group col-md-6 col-sm-12">
                            <label htmlFor="language" className="">
                              Language
                            </label>
                            <input
                              disabled
                              id="language"
                              className="form-control border-none input-group-lg"
                              type="text"
                              name="language"
                              title="Enter Language "
                              placeholder="Language "
                              value={data?.language}
                            />

                          </div>
                        </div>
                        <div className="row">
                          <div className="form-group col-md-6 col-sm-12">
                            <label htmlFor="onReserveMembership">
                              On-reserve Membership
                            </label>
                            <input
                              disabled
                              id="onReserveMembership"
                              className="form-control border-none input-group-lg"
                              type="number"
                              name="onReserveMembership"
                              title="Enter On-reserve Membership"
                              placeholder="On-reserve Membership"
                              value={data?.on_reserve_membership}
                            />

                          </div>
                          <div className="form-group col-md-6 col-sm-12">
                            <label htmlFor="offReserveMembership" className="">
                              Off-reserve Membership
                            </label>
                            <input
                              disabled
                              id="offReserveMembership"
                              className="form-control border-none input-group-lg"
                              type="number"
                              name="offReserveMembership"
                              title="Enter Off-reserve Membership"
                              placeholder="Off-reserve Membership"
                              value={data?.off_reserve_membership}
                            />

                          </div>
                        </div>
                        <div className="row">
                          <div className="form-group col-md-6 col-sm-12">
                            <label htmlFor="chief">Chief</label>
                            <input
                              disabled
                              id="chief"
                              className="form-control border-none input-group-lg"
                              type="text"
                              name="chief"
                              title="Enter Chief"
                              placeholder="Chief"
                              value={data?.chief}
                            />

                          </div>
                          <div className="form-group col-md-6 col-sm-12">
                            <label htmlFor="councillors" className="">
                              Councillors
                            </label>
                            <input
                              disabled
                              id="councillors"
                              className="form-control border-none input-group-lg"
                              type="text"
                              name="councillors"
                              title="Enter Councillors "
                              placeholder="Councillors "
                              value={data?.councillor}
                            />

                          </div>
                        </div>
                        <div className="row">
                          <div className="form-group col-md-6 col-sm-12">
                            <label htmlFor="term">Term </label>
                            <input
                              disabled
                              id="term"
                              className="form-control border-none input-group-lg"
                              type="number"
                              name="term"
                              title="Enter Term "
                              placeholder="Term "
                              value={data?.term}
                            />

                          </div>
                          <div className="form-group col-md-6 col-sm-12">
                            <label htmlFor="electionDate" className="">
                              Election Date
                            </label>
                            <input
                              disabled
                              id="electionDate"
                              className="form-control border-none input-group-lg"
                              type="date"
                              name="electionDate"
                              title="Enter Election Date"
                              placeholder="Election Date"
                              value={data?.election_date}
                            />

                          </div>
                        </div>
                        <div className="row">
                          <div className="form-group col-md-6 col-sm-12">
                            <label htmlFor="economicDevelopmentCorpName">
                              Economic Development Corp Name
                            </label>
                            <input
                              disabled
                              id="economicDevelopmentCorpName"
                              className="form-control border-none input-group-lg"
                              type="text"
                              name="economicDevelopmentCorpName"
                              title="Enter Economic Development Corp Name"
                              placeholder="Economic Development Corp Name"
                              value={data?.economic_development_corp}
                            />

                          </div>
                          <div className="form-group col-md-6 col-sm-12">
                            <label
                              htmlFor="economicDevelopmentCorpWebsite"
                              className=""
                            >
                              Economic Development Corp Website URL
                            </label>
                            <input
                              disabled
                              id="economicDevelopmentCorpWebsite"
                              className="form-control border-none input-group-lg"
                              type="text"
                              name="economicDevelopmentCorpWebsite"
                              title="Enter Economic Development Corp Website URL"
                              placeholder="Economic Development Corp Website URL"
                              value={data?.ec_dev_corp_website}
                            />

                          </div>
                        </div>
                        <div className="row">
                          <div className="form-group col-md-6 col-sm-12">
                            <label htmlFor="existingCompanies">
                              Existing Companies
                            </label>
                            <input
                              disabled
                              id="existingCompanies"
                              className="form-control border-none input-group-lg"
                              type="text"
                              name="existingCompanies"
                              title="Enter Existing Companies"
                              placeholder="Existing Companies"
                              value={data?.existing_company}
                            />

                          </div>
                          <div className="form-group col-md-6 col-sm-12">
                            <label htmlFor="equityInvestments" className="">
                              Equity Investments
                            </label>
                            <input
                              disabled
                              id="equityInvestments"
                              className="form-control border-none input-group-lg"
                              type="text"
                              name="equityInvestments"
                              title="Enter Equity Investments"
                              placeholder="Equity Investments"
                              value={data?.equity_investment}
                            />

                          </div>
                        </div>
                        <div className="row">
                          <div className="form-group col-md-6 col-sm-12">
                            <label htmlFor="partners">Partners </label>
                            <input
                              disabled
                              id="partners"
                              className="form-control border-none input-group-lg"
                              type="text"
                              name="partners"
                              title="Enter Partners "
                              placeholder="Partners "
                              value={data?.partner}
                            />

                          </div>
                          <div className="form-group col-md-6 col-sm-12">
                            <label htmlFor="news" className="">
                              News
                            </label>
                            <input
                              disabled
                              id="news"
                              className="form-control border-none input-group-lg "
                              type="text"
                              name="news"
                              title="Enter News "
                              placeholder="News "
                              value={data?.news}
                            />

                          </div>
                        </div>

                      </form>
                    </div>
                  </div>
                </div>

              </div>
            </div>
          </div>
        </div>
      </Layout>
    </>
  );
};

export default ProfileViewDetail;
