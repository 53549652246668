/* eslint-disable no-unused-vars */
/* eslint-disable eqeqeq */
/* eslint-disable jsx-a11y/anchor-is-valid */
import * as Yup from "yup";
import { useFormik } from "formik";
import { NavLink, useNavigate } from "react-router-dom";
import axiosInstance from "../../../https/axios";
import { useState } from "react";
import { toast } from "react-toastify";
import { Loader } from "../../loader/Loader";
import { BiShow } from "react-icons/bi";
import { BiSolidHide } from "react-icons/bi";
import NewAxiosInstance from "../../../https/newAxios";

function LoginSubcriber() {
  const navigate = useNavigate();
  const [loader, setLoader] = useState(false);
  const [show, setShow] = useState(false);
  const signupSchema = Yup.object().shape({
    email: Yup.string().email("Invalid email").required("email is required"),
    password: Yup.string().required("password is required"),
  });

  const initialValues = {
    email: "",
    password: "",
  };

  const {
    values,
    errors,
    handleBlur,
    handleChange,
    handleSubmit,
    touched,
    resetForm,
  } = useFormik({
    initialValues: initialValues,
    validationSchema: signupSchema,
    onSubmit: (values) => {
      setLoader(true);
      console.log(values);
      NewAxiosInstance.post("login", values)
      .then((data) => {
        console.log(data, "data");
          if (data?.data?.status === true) {
            resetForm();
            console.log(data?.data?.access_token);
            localStorage.setItem("access_token", data?.data?.access_token);
            const user = JSON.stringify(data?.data?.user);
            localStorage.setItem("user", user);
            toast.success("Login Successfully !");
            navigate("/profilesubcriber");
            const role = data?.data?.user?.status;
          } else {
            toast.error(data?.data.message, {
              position: "bottom-left",
            });
            setLoader(false);
          }
        })
        .catch((err) => {
          console.log(err?.message);
          toast.error(err?.message, {
            position: "bottom-left",
          });
          setLoader(false);
        });
    },
  });
  return (
    <>
      <section
        id="banner1"
        style={{ backgroundImage: 'url("images/banner1.png")' }}
      >
        <div className="container">
          {/* Sign Up Form
  ================================================= */}
          <div className="sign-up-form">
            <h2 className="text-white">Welcome to Indstree</h2>
            <div className="line-divider" />
            <div className="form-wrapper">
              <p className="signup-text">Intelligence. Verified.</p>
              <form onSubmit={handleSubmit}>
                <fieldset className="form-group">
                <label htmlFor="" className="d-flex justify-content-start" style={{marginLeft:'10px' , fontSize:'16px' , fontWeight: 600}}>Email</label>
                  <input
                    type="email"
                    className="form-control"
                    id="email"
                    name="email"
                    placeholder="Enter email"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.email}
                  />
                  {errors.email && touched.email ? (
                    <span className="text-danger fs-6">{errors.email}</span>
                  ) : null}
                </fieldset>
                <fieldset className="form-group cursor-pointer">
                <label htmlFor="" className="d-flex justify-content-start" style={{marginLeft:'10px' , fontSize:'16px' , fontWeight: 600}}>Password</label>
                  <input
                    type={show ? "text" : "password"}
                    className="form-control"
                    id="password"
                    name="password"
                    placeholder="Enter a password"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.password}
                  />
                  <div className="eye">
                    {show ? (
                      <BiShow
                        onClick={() => setShow(!show)}
                        color="black"
                        size={20}
                      />
                    ) : (
                      <BiSolidHide
                        onClick={() => setShow(!show)}
                        size={20}
                        color="black"
                      />
                    )}
                  </div>
                  {errors.password && touched.password ? (
                    <span className="text-danger fs-6">{errors.password}</span>
                  ) : null}
                </fieldset>
                <NavLink to={"/"}>Forgot Password</NavLink> <br />
                <button type="submit" className="btn-secondary">
                  {loader ? <Loader /> : "Sign in"}
                </button>
              </form>
            </div>
            <NavLink to={"/signupsubcriber"}>
              New to Indstree? Create an account
            </NavLink>
            <img
              className="form-shadow"
              src="images/bottom-shadow.png"
              alt=""
            />
          </div>
        </div>
      </section>
    </>
  );
}

export default LoginSubcriber;
