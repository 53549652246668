/* eslint-disable array-callback-return */
/* eslint-disable no-self-compare */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
/* eslint-disable eqeqeq */
/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable jsx-a11y/img-redundant-alt */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from "react";
import "./newsfeed.css";
import { toast } from "react-toastify";
import { Loader } from "../loader/Loader";
import { IoCheckmarkCircle } from "react-icons/io5";
import NewAxiosInstance from "../../https/newAxios";
import { Link, useNavigate } from "react-router-dom";
import { ColorRing } from "react-loader-spinner";
import Skeleton from "../Skeleton/Skeleton";

const MainContent = () => {
  const navigate = useNavigate();
  const [newsFeed, setNewsFeed] = useState([]);
  const [newsFeedSubcriber, setNewsFeedSubcriber] = useState([]);
  const [loader, setLoader] = useState(false);
  const [loading, setLoading] = useState(false);
  const [headerPhoto, setHeaderPhoto] = useState(null);
  const user = JSON.parse(localStorage?.getItem("user"));
  const token = localStorage?.getItem("access_token");
  const [loaderPage, setLoaderPage] = useState(false);
  const [loaderFollow, setLoaderFollow] = useState(false);
  const [mewFollower, setMewFollower] = useState([]);
  const [newFollowing, setNewFollowing] = useState([]);
  const [filteredFollowers, setFilteredFollowers] = useState([]);

  const [description, setDescription] = useState("");
  const [error, setError] = useState("");

  const allNewsFeedOne = async () => {
    setLoading(true);
    setLoaderPage(true);
    console.log(user?.id);
    try {
      const data = await NewAxiosInstance.get(
        `organization/post/view/${user?.id}`
      );
      console.log(data?.data);
      setNewsFeed(data?.data?.data);
      setLoading(false);
      setLoaderPage(false);
    } catch (err) {
      console.log(err);
      setLoading(false);
      setLoaderPage(false);
    }
  };

  const allNewsFeedSubcriber = async () => {
    setLoading(true);
    setLoaderPage(true);
    try {
      const data = await NewAxiosInstance.get(`organization/view/${user?.id}`);

      // const newmaps22 = data?.data?.followers?.map((items) => {
      //   const filtered = items?.followers?.filter(
      //     (item) => item?.id === user?.id && item?.pivot?.status === "follow"
      //   );
      //   console.log(filtered, "filtered");
      // });


      // Follow Data Matching Equal


      const newmaps1 = data?.data?.followers?.map((items) => items?.id);
      const new_O_id1 = data?.data?.data?.map((item) => item?.id);
      const final_data1 = new_O_id1.filter((item) => newmaps1.includes(item));
      const filter_Data1 = data?.data?.data?.filter((i) =>
        final_data1.includes(i?.id)
      );
      setMewFollower(filter_Data1);

      // Follow Data Matching Equal
      const newmaps = data?.data?.followers?.map((items) => items?.id);
      const new_O_id = data?.data?.data?.map((item) => item?.id);
      const final_data = new_O_id.filter((item) => !newmaps.includes(item));
      const filter_Data = data?.data?.data?.filter((i) =>
        final_data.includes(i?.id)
      );
      setNewFollowing(filter_Data);

      setNewsFeedSubcriber(data?.data?.data);
      setLoading(false);
      setLoaderPage(false);
    } catch (err) {
      console.log(err);
      setLoading(false);
      setLoaderPage(false);
    }
  };

  useEffect(() => {
    if (user?.role == "user") {
      allNewsFeedSubcriber();
    } else {
      allNewsFeedOne();
    }
  }, []);

  const handleHeaderPhotoChange = (event) => {
    const file = event.target.files[0];
    setHeaderPhoto(file);
  };

  const handleUploadHeaderPhoto = () => {
    document.getElementById("headerPhotoInput").click();
  };

  const handleChange = (event) => {
    setDescription(event.target.value);
    if (event.target.value.length < 200) {
      setError("Description must be at least 200 characters.");
    } else {
      setError("");
    }
  };

  const handleSubmit = (e) => {
    if (token) {
      setLoader(true);
      e.preventDefault();
      const formData = new FormData();
      formData.append("organization_id", user?.id);
      formData.append("post_image", headerPhoto);
      formData.append("post_description", description);
      if (description.length >= 200) {
        NewAxiosInstance.post("organization/post/store", formData)
          .then((data) => {
            console.log(data);
            toast.success(data?.data?.message);
            allNewsFeedOne();
            setLoader(false);
            setDescription("");
          })
          .catch((err) => {
            console.log(err);
            setLoader(false);
          });
        console.log("API called");
      } else {
        setError("Description must be at least 200 characters.");
        setLoader(false);
      }
    } else {
      navigate("/loginsubcriber");
    }
  };
  const handleVisit = (id, status) => {
    setLoader(true);
    if (status === "follow") {
      console.log(status, "name")
      // e.preventDefault();
      const formData = new FormData();
      formData.append("organization_id", id);
      formData.append("description", user?.first_name);
      NewAxiosInstance.post("/follow/activity/process", formData)
        .then((data) => {
          console.log(data);
          if (data.data?.status === true) {
            // toast.success(data?.data?.message);
            setLoader(false);
          } else {
            console.log("error")
            toast.success(data?.data?.message);
            setLoader(false);
          }
        })
        .catch((err) => {
          console.log(err);
          setLoader(false);
        });
      console.log("API called");
    } else if (status === "unfollow") {
      console.log(status, "name")
      // e.preventDefault();
      const formData = new FormData();
      formData.append("organization_id", id);
      formData.append("description", user?.first_name);
      NewAxiosInstance.post("/unfollow/activity/process", formData)
        .then((data) => {
          console.log(data);
          if (data.data?.status === true) {
            // toast.success(data?.data?.message);
            setLoader(false);
          } else {
            console.log("error")
            toast.success(data?.data?.message);
            setLoader(false);
          }
        })
        .catch((err) => {
          console.log(err);
          setLoader(false);
        });
      console.log("API called");
    } else {
      const formData = new FormData();
      formData.append("organization_id", id);
      formData.append("description", user?.first_name);
      NewAxiosInstance.post("/unfollow/activity/process", formData)
        .then((data) => {
          console.log(data);
          if (data.data?.status === true) {
            // toast.success(data?.data?.message);
            setLoader(false);
            navigate(`/profileviewdetail/${id}`)
          } else {
            console.log("error")
            toast.success(data?.data?.message);
            setLoader(false);
          }
        })
        .catch((err) => {
          console.log(err);
          setLoader(false);
        });
    }
  };

  const handleProfileFollow = (id, status, name) => {
    console.log(id, "follow");
    setLoaderFollow(true);
    const formData = new FormData();
    formData.append("user_id", user?.id);
    formData.append("organization_id", id);
    formData.append("status", status);
    formData.append("organization_name", name);
    NewAxiosInstance.post(`organization/follow`, formData)
      .then((data) => {
        console.log(data);
        toast.success(data?.data?.message);
        allNewsFeedSubcriber();
        handleVisit(id, status)
        setLoaderFollow(false);
      })
      .catch((err) => {
        console.log(err);
        setLoaderFollow(false);
      });
  };

  return (
    <>
      {/*Header End*/}
      <div id="page-contents">
        <div className="container">
          <div className="row rowspace">
            {/* Newsfeed Common Side Bar Left
    ================================================= */}
            {user?.role == "user" ? (
              <div className="col-md-3 col-sm-12 static">
                <div className="profile-card">
                  <img
                    src="images/users/user-1.jpg"
                    alt="user"
                    className="profile-photo"
                  />
                  <h5>
                    <a href="#" className="text-white">
                      Sarah Cruiz
                    </a>
                  </h5>
                  <a href="#" className="text-white">
                    <i className="ion ion-android-person-add" />{" "}
                    {/* {mewFollower?.length} */}
                    followers
                  </a>
                </div>
                {/*profile card ends*/}
                <ul className="nav-news-feed">
                  <div className="col-md-12 margin-three">
                    <h2 className="px-40 fw-bold mt-0">Following</h2>
                    <ul className="nav-news-feed">
                      {loaderFollow ? (
                        <Skeleton />
                      ) : (
                        <>
                          {mewFollower?.map((post) => {
                            return (
                              <li>
                                <div className="d-flex-comp">
                                  <h5 className="fw-bold">
                                    {post?.first_nation}
                                  </h5>
                                  <button
                                    className="btn-secondary tab follow-button"
                                    onClick={() =>
                                      handleProfileFollow(
                                        post?.id,
                                        "unfollow",
                                        post?.first_nation
                                      )
                                    }
                                  >
                                    following
                                  </button>
                                </div>
                              </li>
                            );
                          })}
                        </>
                      )}
                    </ul>
                  </div>
                </ul>
              </div>
            ) : null}

            {loaderPage ? (
              <div className="col-lg-8 col-sm-12 d-flex justify-content-center align-items-center">
                <ColorRing
                  visible={true}
                  height="80"
                  width="80"
                  ariaLabel="color-ring-loading"
                  wrapperStyle={{}}
                  wrapperClass="color-ring-wrapper"
                  colors={[
                    "#336230",
                    "#336230",
                    "#336230",
                    "#336230",
                    "#336230",
                  ]}
                />
              </div>
            ) : (
              <>
                {user?.role == "user" ? (
                  <div className={"col-md-6"}>
                    {newsFeedSubcriber?.map((post) => (
                      <>
                        {post?.organization_post?.map((posts) => (
                          <>
                            <>
                              <div className="post-content cursor-pointer">
                                <div className="post-container">
                                  <img
                                    src={
                                      post?.logo
                                        ? post?.logo
                                        : "images/users/user-5.jpg"
                                    }
                                    alt="user"
                                    className="profile-photo-md pull-left"
                                  />
                                  <div className="post-detail">
                                    <div className="user-info">
                                      <h5 onClick={() => handleVisit(posts.id)}>
                                        {/* <Link to={`/profileviewdetail/${posts.id}`} className="profile-link"> */}
                                        {post?.first_nation}
                                        {/* </Link>{" "} */}
                                      </h5>
                                      <p className="text-muted">
                                        Published a photo about 3 mins ago
                                      </p>
                                    </div>
                                    <div className="line-divider" />
                                    <div className="post-text">
                                      <dev
                                        state={{ post: post, posts: posts }}
                                      >
                                        <p>
                                          {posts?.post_description}{" "}
                                          <i className="em em-anguished" />{" "}
                                          <i className="em em-anguished" />{" "}
                                          <i className="em em-anguished" />
                                        </p>
                                      </dev>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </>
                          </>
                        ))}
                      </>
                    ))}
                  </div>
                ) : (
                  <div className={"col-md-8"}>
                    {/* Post Create Box ======= */}
                    <>
                      {/* <Modals /> */}
                      <div className="create-post">
                        <div className="row">
                          <form
                            style={{ position: "relative" }}
                            onSubmit={handleSubmit}
                          >
                            <div className="col-md-8 col-sm-8">
                              <div className="form-group">
                                <img
                                  src="images/users/user-1.jpg"
                                  alt=""
                                  className="profile-photo-md"
                                />
                                <textarea
                                  name="texts"
                                  id="exampleTextarea"
                                  cols="50"
                                  rows="2"
                                  className="form-control"
                                  placeholder="Write what you wish"
                                  maxLength="1000"
                                  value={description}
                                  onChange={handleChange}
                                ></textarea>
                              </div>
                            </div>
                            <div className="col-md-4 col-sm-4">
                              <div className="tools">
                                <ul className="publishing-tools list-inline d-flex align-items-center">
                                  <div className="upload-button mr-20">
                                    {headerPhoto ? (
                                      <a
                                        href="#"
                                        onClick={handleUploadHeaderPhoto}
                                      >
                                        <IoCheckmarkCircle
                                          color="#04283a"
                                          size={30}
                                        />
                                      </a>
                                    ) : (
                                      <a
                                        href="#"
                                        onClick={handleUploadHeaderPhoto}
                                      >
                                        <i
                                          className="ion-images"
                                          style={{ fontSize: "30px" }}
                                        ></i>
                                      </a>
                                    )}
                                    <input
                                      type="file"
                                      id="headerPhotoInput"
                                      accept="image/*"
                                      style={{ display: "none" }}
                                      onChange={handleHeaderPhotoChange}
                                    />
                                  </div>
                                  <button
                                    className="btn m-0 btn-primary pull-right"
                                    type="submit"
                                  >
                                    {loader ? <Loader /> : "Publish"}
                                  </button>
                                </ul>
                              </div>
                            </div>
                            {error && (
                              <p
                                style={{
                                  color: "red",
                                  position: " absolute",
                                  top: "60px",
                                  left: "70px",
                                }}
                              >
                                {error}
                              </p>
                            )}
                          </form>
                        </div>
                      </div>
                    </>

                    {newsFeed?.map((post) => (
                      <div className="post-content">
                        <div className="post-container">
                          <img
                            src={
                              user?.logo
                                ? user?.logo
                                : "images/users/user-5.jpg"
                            }
                            alt="user"
                            className="profile-photo-md pull-left"
                          />
                          <div className="post-detail">
                            <div className="user-info">
                              <h5>
                                <a href="#" className="profile-link">
                                  {user?.first_nation}
                                </a>{" "}
                              </h5>
                              <p className="text-muted">
                                Published a photo about 3 mins ago
                              </p>
                            </div>
                            <div className="reaction">
                              <span className="following">
                                <i
                                  className="fa fa-plus"
                                  style={{
                                    marginRight: "10px",
                                    fontSize: "medium",
                                  }}
                                />
                                follow
                              </span>
                            </div>
                            <div className="line-divider" />
                            <div className="post-text">
                              <p>
                                {post?.post_description}{" "}
                                <i className="em em-anguished" />{" "}
                                <i className="em em-anguished" />{" "}
                                <i className="em em-anguished" />
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                )}
              </>
            )}
            {user?.role == "user" ? (
              <div className="col-md-3 col-sm-12 static margin-three">
                <h2 className="px-40 fw-bold">Follow</h2>
                <ul className="nav-news-feed">
                  {loaderFollow ? (
                    <Skeleton />
                  ) : (
                    <>
                      {newFollowing?.map((post) => (
                        <li>
                          <div className="d-flex-comp">
                            <h5 className="fw-bold">{post?.first_nation}</h5>
                            <button
                              className="btn-secondary tab follow-button"
                              onClick={() =>
                                handleProfileFollow(
                                  post?.id,
                                  "follow",
                                  post?.first_nation
                                )
                              }
                            >
                              follow
                            </button>
                          </div>
                        </li>
                      ))}
                    </>
                  )}
                </ul>
              </div>
            ) : null}
          </div>
        </div>
      </div>
    </>
  );
};

export default MainContent;
