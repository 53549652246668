/* eslint-disable jsx-a11y/img-redundant-alt */
/* eslint-disable jsx-a11y/anchor-is-valid */
import { useState } from "react";
import { Link, NavLink } from "react-router-dom";

function Navbar() {
  const [isOpen, setIsOpen] = useState(false);
  const token = localStorage.getItem("access_token");
  const user = JSON.parse(localStorage?.getItem("user"));

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  return (
    <>
      <nav className="navbar navbar-default navbar-fixed-top menu">
        <div className="container">
          {/* Brand and toggle get grouped for better mobile display */}
          <div className="navbar-header">
            <button
              type="button"
              className="navbar-toggle collapsed"
              data-toggle="collapse"
              data-target="#bs-example-navbar-collapse-1"
              aria-expanded="false"
            >
              <span className="sr-only">Toggle navigation</span>
              <span className="icon-bar" />
              <span className="icon-bar" />
              <span className="icon-bar" />
            </button>
            <NavLink
              className="navbar-brand"
              to={"/"}
            >
              <img
                src="/images/logo.png"
                alt="logo"
                style={{ width: "80px", height: "75px" }}
              />
            </NavLink>
          </div>
          {/* Collect the nav links, forms, and other content for toggling */}
          <div
            className="collapse navbar-collapse"
            id="bs-example-navbar-collapse-1"
          >
            <ul className="nav navbar-nav navbar-right main-menu">
              <li className="dropdown">
                <Link
                  to="/"
                  className="dropdown-toggle"
                  data-toggle="dropdown"
                  role="button"
                  aria-haspopup="true"
                  aria-expanded="false"
                  // onClick={() => (window.location.href = "/")}
                  style={{
                    borderBottom:
                      window.location.pathname === "/"
                        ? `2px solid #04283a`
                        : "none",
                  }}
                >
                  Home{" "}
                </Link>
              </li>

              <li className="dropdown">
                <Link
                  to={token ? '/newsfeed' : `/loginsubcriber`}
                  className="dropdown-toggle"
                  data-toggle="dropdown"
                  role="button"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  Newsfeed{" "}
                </Link>
              </li>
              {user?.role === "user" ? null : (
                <li className="dropdown">
                  <Link
                    to="/subcription"
                    className="dropdown-toggle pages"
                    data-toggle="dropdown"
                    role="button"
                    aria-haspopup="true"
                    aria-expanded="false"
                    style={{
                      borderBottom:
                        window.location.pathname === "/subcription"
                          ? `2px solid #04283a`
                          : "none",
                    }}
                  >
                    Subcription{" "}
                  </Link>
                </li>
              )}
              <li className="dropdown">
                <Link
                  to="/about"
                  className="dropdown-toggle pages"
                  data-toggle="dropdown"
                  role="button"
                  aria-haspopup="true"
                  aria-expanded="false"
                  style={{
                    borderBottom:
                      window.location.pathname === "/about"
                        ? `2px solid #04283a`
                        : "none",
                  }}
                >
                  About{" "}
                </Link>
              </li>

              <li className="dropdown">
                <Link
                  to="/contact"
                  style={{
                    borderBottom:
                      window.location.pathname === "/contact"
                        ? `2px solid #04283a`
                        : "none",
                  }}
                >
                  Contact us
                </Link>
              </li>

              {token ? (
                <li>
                  {user?.role === "user" ? (
                    <div className="dropdown-container">
                      <img
                        src={user?.logo ? user?.logo : "/images/logo.png"} // Assuming user.id is the unique identifier for each user
                        alt="logo"
                        onClick={toggleDropdown}
                        className="img-demo"
                      />
                      {isOpen && (
                        <div className="dropdown-options">
                          <div
                            style={{
                              textAlign: "center",
                              backgroundImage: `url('${
                                user?.header_photo
                                  ? user?.header_photo
                                  : "/images/industree1.png"
                              }')`,
                              justifyContent: "center",
                              alignItems: "center",
                              display: "flex",
                              borderTopLeftRadius: "10px",
                              borderTopRightRadius: "10px",
                              height: "20vh",
                              backgroundSize: "cover",
                            }}
                          >
                            <img
                              src={user?.logo ? user?.logo : "/images/logo.png"} // Assuming user.id is the unique identifier for each user
                              alt="logo"
                              onClick={toggleDropdown}
                              style={{
                                borderRadius: "25px",
                                position: "relative",
                                top: "55px",
                                width: "60px",
                                height: "60px",
                                border: "solid 2px white",
                              }}
                            />
                          </div>

                          <div
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                              background: "darkgray",
                            }}
                          >
                            {" "}
                            <Link
                              to={"/profilesubcriber"}
                              style={{
                                lineHeight: "26px",
                                fontSize: "15px",
                                fontWeight: "bold",
                                color: "black",
                              }}
                            >
                              <button onClick={toggleDropdown}>Profile</button>
                            </Link>
                            <Link
                              style={{
                                lineHeight: "26px",
                                fontSize: "15px",
                                fontWeight: "bold",
                                color: "black",
                              }}
                              to={"/"}
                              onClick={() => localStorage.clear()}
                            >
                              <button onClick={toggleDropdown}>Logout</button>
                            </Link>
                          </div>

                          <h4 style={{ color: "black", textAlign: "center" }}>
                            Hi,{" "}
                            {user?.first_nation
                              ? user?.first_nation
                              : user?.first_name + " " + user?.last_name}
                            !
                          </h4>
                          <h5 style={{ color: "black", textAlign: "center" }}>
                            {user?.mailing_address
                              ? user?.mailing_address
                              : user?.email}
                          </h5>
                        </div>
                      )}
                    </div>
                  ) : (
                    <div className="dropdown-container">
                      <img
                        src={user?.logo ? user?.logo : "/images/logo.png"} // Assuming user.id is the unique identifier for each user
                        alt="logo"
                        onClick={toggleDropdown}
                        className="img-demo"
                      />
                      {isOpen && (
                        <div className="dropdown-options">
                          <div
                            style={{
                              textAlign: "center",
                              backgroundImage: `url('${
                                user?.header_photo
                                  ? user?.header_photo
                                  : "/images/industree1.png"
                              }')`,
                              justifyContent: "center",
                              alignItems: "center",
                              display: "flex",
                              borderTopLeftRadius: "10px",
                              borderTopRightRadius: "10px",
                              height: "20vh",
                              backgroundSize: "cover",
                            }}
                          >
                            <img
                              src={user?.logo ? user?.logo : "/images/logo.png"} // Assuming user.id is the unique identifier for each user
                              alt="logo"
                              onClick={toggleDropdown}
                              style={{
                                borderRadius: "50%",
                                position: "relative",
                                top: "55px",
                                width: "60px",
                                height: "60px",
                                border: "solid 2px white",
                              }}
                            />
                          </div>

                          <div
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                              background: "darkgray",
                            }}
                          >
                            {" "}
                            <Link
                              to={"/profileorganization"}
                              style={{
                                lineHeight: "26px",
                                fontSize: "15px",
                                fontWeight: "bold",
                                color: "black",
                              }}
                            >
                              <button onClick={toggleDropdown}>Profile</button>
                            </Link>
                            <Link
                              style={{
                                lineHeight: "26px",
                                fontSize: "15px",
                                fontWeight: "bold",
                                color: "black",
                              }}
                              to={"/"}
                              onClick={() => localStorage.clear()}
                            >
                              <button onClick={toggleDropdown}>Logout</button>
                            </Link>
                          </div>

                          <h4 style={{ color: "black", textAlign: "center" }}>
                            Hi,{" "}
                            {user?.first_nation
                              ? user?.first_nation
                              : user?.first_name + " " + user?.last_name}
                            !
                          </h4>
                          <h5 style={{ color: "black", textAlign: "center" }}>
                            {user?.mailing_address
                              ? user?.mailing_address
                              : user?.email}
                          </h5>
                        </div>
                      )}
                    </div>
                  )}
                </li>
              ) : (
                <li>
                  <div className="dropdown-container1">
                    <button className="login-button1" onClick={toggleDropdown}>
                      Login
                    </button>
                    {isOpen && (
                      <div className="dropdown-options1">
                        <Link to={"/loginsubcriber"}>
                          <button onClick={toggleDropdown}>
                            Login Subcriber
                          </button>
                        </Link>
                        <Link to={"/loginorganization"}>
                          <button onClick={toggleDropdown}>
                            Login Oraganization
                          </button>
                        </Link>
                      </div>
                    )}
                  </div>
                </li>
              )}
            </ul>
          </div>
          {/* /.navbar-collapse */}
        </div>
        {/* /.container */}
      </nav>
    </>
  );
}
export default Navbar;
