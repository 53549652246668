import React from "react";
import Layout from "../layout/Layout";
import MainContent from "../contact/MainContent";

const Contact = () => {
  return (
    <>
      <Layout>
        <MainContent />
      </Layout>
    </>
  );
};

export default Contact;
