import * as Yup from "yup";

export const initialValuesOraganizer = {
  firstNationName: "",
  website: "",
  mailingAddress: "",
  city1: "",
  province: "",
  postalCode: "",
  country1: "",
  phoneNumber: "",
  treaty: "",
  tribalCouncil: "",
  physicalLocation: "",
  language: "",
  onReserveMembership: "",
  offReserveMembership: "",
  chief: "",
  councillors: "",
  term: "",
  electionDate: "",
  economicDevelopmentCorpName: "",
  economicDevelopmentCorpWebsite: "",
  existingCompanies: "",
  equityInvestments: "",
  partners: "",
  news: "",
};

export const validationSchemaOraganizer = Yup.object({
  firstNationName: Yup.string().required("First Nation Name is required"),
  website: Yup.string()
    .url("Invalid URL format")
    .required("Website URL is required"),
  mailingAddress: Yup.string().required("Mailing Address is required"),
  city1: Yup.string().required("City is required"),
  province: Yup.string().required("Province is required"),
  postalCode: Yup.string().required("Postal Code is required"),
  country1: Yup.string().required("Country is required"),
  phoneNumber: Yup.string().required("Phone Number is required"),
  treaty: Yup.string().required("Treaty is required"),
  tribalCouncil: Yup.string().required("Tribal Council is required"),
  physicalLocation: Yup.string().required("Physical Location is required"),
  language: Yup.string().required("Language is required"),
  onReserveMembership: Yup.string().required(
    "On-reserve Membership is required"
  ),
  offReserveMembership: Yup.string().required(
    "Off-reserve Membership is required"
  ),
  chief: Yup.string().required("Chief is required"),
  councillors: Yup.string().required("Councillors is required"),
  term: Yup.string().required("Term is required"),
  electionDate: Yup.string().required("Election Date is required"),
  economicDevelopmentCorpName: Yup.string().required(
    "Economic Development Corp Name is required"
  ),
  economicDevelopmentCorpWebsite: Yup.string()
    .url("Invalid URL format")
    .required("Economic Development Corp Website URL is required"),
  existingCompanies: Yup.string().required("Existing Companies is required"),
  equityInvestments: Yup.string().required("Equity Investments is required"),
  partners: Yup.string().required("Partners is required"),
  news: Yup.string().required("News is required"),
});

export const validationSchemaSubcribe = Yup.object({
  firstname: Yup.string().required("First Name is required"),
  lastname: Yup.string().required("Last Name is required"),
  email: Yup.string()
    .email("Invalid email address")
    .required("Email is required"),
  companyname: Yup.string().required("Company Name is required"),
  jobtitle: Yup.string().required("Job Title is required"),
  city: Yup.string().required("City is required"),
  provincestate: Yup.string().required("Province/State is required"),
  country: Yup.string().required("Country is required"),
  notification: Yup.string().required("Notification is required"),
  subscriptionlevel: Yup.string().required("Subscription Level is required"),
  billinginformation: Yup.string().required("Billing Information is required"),
});

export const initialValuesSubcribe = {
  firstname: "",
  lastname: "",
  email: "",
  companyname: "",
  jobtitle: "",
  city: "",
  provincestate: "",
  country: "",
  notification: "",
  subscriptionlevel: "",
  billinginformation: "",
};
