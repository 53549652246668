// Swipers.js

import React, { useEffect, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import { Autoplay, Pagination, Navigation } from "swiper/modules";
import "./swiper.css";

const Swipers = () => {
  const [showAnimation, setShowAnimation] = useState(false);

  useEffect(() => {
    const swiperContainer = document.querySelector(".mySwiper");

    if (swiperContainer) {
      swiperContainer.addEventListener("transitionend", () => {
        setShowAnimation(true);
      });
    }

    return () => {
      if (swiperContainer) {
        swiperContainer.removeEventListener("transitionend", () => {
          setShowAnimation(true);
        });
      }
    };
  }, []);

  return (
    <>
      <Swiper
        spaceBetween={30}
        centeredSlides={true}
        autoplay={{
          delay: 2500,
          disableOnInteraction: false,
        }}
        pagination={{
          clickable: true,
        }}
        navigation={true}
        modules={[Autoplay, Pagination, Navigation]}
        className="mySwiper"
      >
        <SwiperSlide
          className="swiper-one"
          style={{ backgroundImage: 'url("/images/banner1.png")' }}
        >
          <span
            className={`gray-background ${
              showAnimation ? "animate-boxes" : ""
            }`}
          >
            <div className="swiper-text">
              <div className="box left"></div>
              <h1 className="text-60">
                {window.location.pathname === "/"
                  ? "Welcome to INDUSTREE"
                  : window.location.pathname === "/about"
                  ? "About"
                  : "Subscription"}
              </h1>
              <div className="box right"></div>
            </div>
          </span>
        </SwiperSlide>
        <SwiperSlide
          className="swiper-one"
          style={{ backgroundImage: 'url("/images/banner2.png")' }}
        >
          <span
            className={`gray-background ${
              showAnimation ? "animate-boxes" : ""
            }`}
          >
            <div className="swiper-text">
              <div className="box left"></div>
              <h1 className="text-60">
                {window.location.pathname === "/"
                  ? "Welcome to INDUSTREE"
                  : window.location.pathname === "/about"
                  ? "About"
                  : "Subscription"}
              </h1>
              <div className="box right"></div>
            </div>
          </span>
        </SwiperSlide>
      </Swiper>
    </>
  );
};

export default Swipers;
