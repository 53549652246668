import React from 'react'
import { Rings } from 'react-loader-spinner'

export const Loader = () => {
    return (
        <div>
            <Rings
                visible={true}
                height="40"
                width="40"
                color="#fff"
                ariaLabel="rings-loading"
                wrapperStyle={{}}
                wrapperClass=""
            />
        </div>
    )
}
