/* eslint-disable jsx-a11y/img-redundant-alt */
/* eslint-disable eqeqeq */
/* eslint-disable jsx-a11y/anchor-is-valid */
import { useFormik } from "formik";
import { NavLink, useNavigate } from "react-router-dom";
import { useState } from "react";
import { initialValuesOraganizer, validationSchemaOraganizer } from "./schema";
import NewAxiosInstance from "../../../https/newAxios";
import { toast } from "react-toastify";
import { Loader } from "../../loader/Loader";
import { BiHide, BiShow } from "react-icons/bi";

function SignupOrganization() {
  const navigate = useNavigate();
  const [loader, setLoader] = useState(false);
  const [showPassword, setShowPassword] = useState(false);

  const {
    values,
    errors,
    handleBlur,
    handleChange,
    handleSubmit,
    touched,
    resetForm,
  } = useFormik({
    initialValues: initialValuesOraganizer,
    validationSchema: validationSchemaOraganizer,
    onSubmit: (values) => {
      setLoader(true);
      const formData = new FormData();
      formData.append("first_nation", values.firstNationName);
      formData.append("mailing_address", values.mailingAddress);
      formData.append("password", values.password);
      formData.append("city", values.city1);
      formData.append("province", values.province);
      formData.append("postal_code", values.postalCode);
      formData.append("country", values.country1);
      formData.append("phone_no", values.phoneNumber);
      formData.append("role", "user");
      NewAxiosInstance.post("organization/register", formData)
        .then((response) => {
          console.log(response);
          toast.success(response?.data?.message);
          navigate("/loginorganization");
          resetForm();
          setLoader(false);
        })
        .catch((error) => {
          console.log(error);
          toast.error(error?.response?.data?.message);
        });
    },
  });

  return (
    <>
      <section
        id="banner1"
        style={{
          height: "150vh",
          backgroundImage: 'url("images/banner1.png")',
        }}
      >
        <div className="container">
          <div className="sign-up-form">
            <div className="w-100" style={{ gap: "20px" }}>
              <div>
                <button
                  type="submit"
                  className="btn-secondary tab"
                  style={{ background: "#336230" }}
                >
                  Organization
                </button>
              </div>
            </div>
            <h2 style={{ margin: "30px" }} className="text-white">
              Welcome to Indstree
            </h2>
            <div className="line-divider" />
            <div className="form-wrapper">
              <form onSubmit={handleSubmit}>
                <div className="row flex">
                  <fieldset className="form-group">
                    <label htmlFor="" className="d-flex justify-content-start" style={{marginLeft:'10px' , fontSize:'16px' , fontWeight: 600}}>First Nation Name</label>
                    <input
                      type="text"
                      className="form-control"
                      id="firstNationName"
                      name="firstNationName"
                      placeholder="Enter First Nation Name"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.firstNationName}
                    />
                    {console.log(touched.firstNationName, "errors")}
                    {errors.firstNationName ? (
                      <span className="text-danger fs-6">
                        {errors.firstNationName}
                      </span>
                    ) : null}
                  </fieldset>
                  <fieldset className="form-group">
                  <label htmlFor="" className="d-flex justify-content-start" style={{marginLeft:'10px' , fontSize:'16px' , fontWeight: 600}}>Mailing Address</label>
                    <input
                      type="email"
                      className="form-control"
                      id="mailingAddress"
                      name="mailingAddress"
                      placeholder="Enter Mailing Address"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.mailingAddress}
                    />
                    {errors.mailingAddress ? (
                      <span className="text-danger fs-6">
                        {errors.mailingAddress}
                      </span>
                    ) : null}
                  </fieldset>
                </div>
                <div className="row flex">
                  <fieldset className="form-group">
                  <label htmlFor="" className="d-flex justify-content-start" style={{marginLeft:'10px' , fontSize:'16px' , fontWeight: 600}}>Password</label>
                    <input
                     type={showPassword ? "text" : "password"}
                      className="form-control"
                      id="password"
                      name="password"
                      placeholder="Enter password"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.password}
                    />
                      <div className="eye">
                    {showPassword ? (
                      <BiShow
                        onClick={() => setShowPassword(false)}
                        color="black"
                        size={20}
                      />
                    ) : (
                      <BiHide
                        onClick={() => setShowPassword(true)}
                        size={20}
                        color="black"
                      />
                    )}
                  </div>
                    {errors.password ? (
                      <span className="text-danger fs-6">
                        {errors.passwordpassword}
                      </span>
                    ) : null}
                  </fieldset>
                  <fieldset className="form-group">
                  <label htmlFor="" className="d-flex justify-content-start" style={{marginLeft:'10px' , fontSize:'16px' , fontWeight: 600}}>City</label>
                    <input
                      type="text"
                      className="form-control"
                      id="city1"
                      name="city1"
                      placeholder="Enter City"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.city1}
                    />
                    {errors.city1 ? (
                      <span className="text-danger fs-6">{errors.city1}</span>
                    ) : null}
                  </fieldset>
                </div>
                <div className="row flex">
                  <fieldset className="form-group">
                  <label htmlFor="" className="d-flex justify-content-start" style={{marginLeft:'10px' , fontSize:'16px' , fontWeight: 600}}>Province</label>
                    <input
                      type="text"
                      className="form-control"
                      id="province"
                      name="province"
                      placeholder="Enter Province"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.province}
                    />
                    {errors.province ? (
                      <span className="text-danger fs-6">
                        {errors.province}
                      </span>
                    ) : null}
                  </fieldset>
                  <fieldset className="form-group">
                  <label htmlFor="" className="d-flex justify-content-start" style={{marginLeft:'10px' , fontSize:'16px' , fontWeight: 600}}>Postal Code</label>
                    <input
                      type="number"
                      className="form-control"
                      id="postalCode"
                      name="postalCode"
                      placeholder="Enter Postal Code"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.postalCode}
                    />
                    {errors.postalCode ? (
                      <span className="text-danger fs-6">
                        {errors.postalCode}
                      </span>
                    ) : null}
                  </fieldset>
                </div>
                <div className="row flex">
                  <fieldset className="form-group">
                  <label htmlFor="" className="d-flex justify-content-start" style={{marginLeft:'10px' , fontSize:'16px' , fontWeight: 600}}>Country</label>
                    <input
                      type="text"
                      className="form-control"
                      id="country"
                      name="country1"
                      placeholder="Enter Country"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.country1}
                    />
                    {errors.country1 ? (
                      <span className="text-danger fs-6">
                        {errors.country1}
                      </span>
                    ) : null}
                  </fieldset>
                  <fieldset className="form-group">
                  <label htmlFor="" className="d-flex justify-content-start" style={{marginLeft:'10px' , fontSize:'16px' , fontWeight: 600}}>Phone Number</label>
                    <input
                      type="number"
                      className="form-control"
                      id="phoneNumber"
                      name="phoneNumber"
                      placeholder="Enter Phone Number"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.phoneNumber}
                    />
                    {errors.phoneNumber ? (
                      <span className="text-danger fs-6">
                        {errors.phoneNumber}
                      </span>
                    ) : null}
                  </fieldset>
                </div>
                <button type="submit" className="btn-secondary">
                  {loader ? <Loader /> : "Sign up"}
                </button>
              </form>
            </div>
            <NavLink to={"/loginorganization"}>
              New to Indstree? Sign in account
            </NavLink>
            <img
              className="form-shadow"
              src="images/bottom-shadow.png"
              alt=""
            />
          </div>
        </div>
      </section>
    </>
  );
}

export default SignupOrganization;
