import React from 'react'
import Layout from '../layout/Layout'
import MainContent from '../newsfeed/MainContent'

const NewsFeed = () => {
  return (
    <>
    <Layout>
        <MainContent/>
    </Layout>
    
    </>
  )
}

export default NewsFeed