import Home from "./components/pages/Home";
import "./App.css";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import NewsFeed from "./components/pages/NewsFeed";
import About from "./components/pages/About";
import Contact from "./components/pages/Contact";
import Login from "./components/pages/auth/Login";
import Subcription from "./components/pages/Subcription";
import Signup from "./components/pages/auth/Signup";
import LoginOrganization from "./components/pages/AuthOrganization/LoginOrganization";
import SignupSubcriber from "./components/pages/AuthSubcriber/SignupSubcriber";
import LoginSubcriber from "./components/pages/AuthSubcriber/LoginSubcriber";
import SignupOrganization from "./components/pages/AuthOrganization/SignupOrganization";
import ProfileOraganization from "./components/profile/ProfileOrganization";
import ProfileSubcriber from "./components/profile/ProfileSubcriber";
import ProfileViewDetail from "./components/newsfeed/ProfileViewDetail";


function App() {
  return (
    <>
      <BrowserRouter>
      <Routes>
        <Route path="/"  element={<Home/>}/>
        <Route path="/login"  element={<Login/>}/>
        <Route path="/signup"  element={<Signup />}/>

        <Route path="/loginsubcriber"  element={<LoginSubcriber />}/>
        <Route path="/signupsubcriber"  element={<SignupSubcriber />}/>
        <Route path="/loginorganization"  element={<LoginOrganization />}/>
        <Route path="/signuporganization"  element={<SignupOrganization />}/>

        
        <Route path="/newsfeed"  element={<NewsFeed/>}/>
        <Route path="/about"  element={<About/>}/>
        <Route path="/contact"  element={<Contact/>}/>
        <Route path="/subcription"  element={<Subcription/>}/>
        <Route path="/profileorganization"  element={<ProfileOraganization />}/>
        <Route path="/profilesubcriber"  element={<ProfileSubcriber />}/>
        <Route path="/profileviewdetail/:id"  element={<ProfileViewDetail />}/>
      </Routes>
      </BrowserRouter>
    </>
  );
}

export default App;
// https://frontendin.com/jquery-cards/



