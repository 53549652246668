/* eslint-disable eqeqeq */
/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from "react";
import * as Yup from "yup";
import Layout from "../layout/Layout";
import { useFormik } from "formik";
import { Loader } from "../loader/Loader";
import NewAxiosInstance from "../../https/newAxios";
import { toast } from "react-toastify";

const ProfileSubcriber = () => {
  const user = JSON.parse(localStorage.getItem("user"));
  const [loader, setLoader] = useState(false);
  const [subcription, setSubcription] = useState([]);
  useEffect(() => {
    NewAxiosInstance.get("show-subscriptions").then((data) => {
      console.log(data?.data?.data, "data");
      const users = data?.data?.data;
      const filteredData = users?.filter(
        (item) => item?.id == user?.subscription_level
      );
      if (filteredData?.length > 0) {
        setSubcription(filteredData[0]);
      }
    });
  }, []);

  const validationSchemaSubcribe = Yup.object({
    firstname: Yup.string().required("First Name is required"),
    lastname: Yup.string().required("Last Name is required"),
    email: Yup.string()
      .email("Invalid email address")
      .required("Email is required"),
    companyname: Yup.string().required("Company Name is required"),
    jobtitle: Yup.string().required("Job Title is required"),
    city: Yup.string().required("City is required"),
    provincestate: Yup.string().required("Province/State is required"),
    country: Yup.string().required("Country is required"),
    notification: Yup.string().required("Notification is required"),
    billinginformation: Yup.string().required(
      "Billing Information is required"
    ),
  });

  const initialValuesSubcribe = {
    firstname: user?.first_name,
    lastname: user?.last_name,
    email: user?.email,
    companyname: user?.company_name,
    jobtitle: user?.job_title,
    city: user?.city,
    provincestate: user?.province,
    country: user?.country,
    notification: user?.notification_permissison,
    billinginformation: user?.billing_information,
  };

  const { values, errors, handleBlur, handleChange, handleSubmit, resetForm } =
    useFormik({
      initialValues: initialValuesSubcribe,
      validationSchema: validationSchemaSubcribe,
      onSubmit: (values) => {
        setLoader(true);
        console.log(values, "values");
        const formData = new FormData();
        formData.append("first_name", values.firstname);
        formData.append("last_name", values.lastname);
        formData.append("email", values.email);
        formData.append("company_name", values.companyname);
        formData.append("job_title", values.jobtitle);
        formData.append("city", values.city);
        formData.append("province", values.provincestate);
        formData.append("country", values.country);
        formData.append("subscription_level", subcription?.id);
        formData.append("notification_permissison", values.notification);
        formData.append("billing_information", values.billinginformation);
        formData.append("role", "user");
        NewAxiosInstance.post(`profile/update/${user?.id}`, formData).then(
          (data) => {
            console.log(data, "data");
            if (data?.data?.status === true) {
              const user = data?.data?.data;
              localStorage.setItem("user", JSON.stringify(user));
              toast.success(data?.data?.message);
              setLoader(false);
              resetForm();
            } else {
              toast.error("An error occurred while updating profile");
              setLoader(false);
            }
          }
        );
      },
    });

  return (
    <>
      <Layout>
        <div className="container">
          {/* Timeline
================================================= */}
          <div className="timeline">
            <div id="page-contents">
              <div
                className="row mt-40"
                style={{ display: "flex", justifyContent: "center" }}
              >
                <div className="col-md-8">
                  {/* Basic Information
        ================================================= */}
                  <div className="edit-profile-container">
                    <div className="block-title">
                      <h4 className="grey">
                        <i className="icon ion-android-checkmark-circle" />
                        Profile Information
                      </h4>
                      <div className="line" />
                      <p>
                        At vero eos et accusamus et iusto odio dignissimos
                        ducimus qui blanditiis praesentium voluptatum deleniti
                        atque corrupti quos dolores et quas molestias excepturi
                        sint occaecati cupiditate
                      </p>
                      <div className="line" />
                    </div>
                    <div className="edit-block">
                      <form
                        name="basic-info"
                        id="basic-info"
                        className="form-inline"
                        onSubmit={handleSubmit}
                      >
                        <div className="row">
                          <div className="form-group col-md-6 col-sm-12">
                            <label htmlFor="firstname">First Name</label>
                            <input
                              id="firstname"
                              className="form-control input-group-lg"
                              type="text"
                              name="firstname"
                              title="Enter first name"
                              placeholder="First name"
                              onChange={handleChange}
                              onBlur={handleBlur}
                              value={values.firstname}
                            />
                            {errors.firstname ? (
                              <span className="text-danger fs-6">
                                {errors.firstname}
                              </span>
                            ) : null}
                          </div>
                          <div className="form-group col-md-6 col-sm-12">
                            <label htmlFor="lastname" className="">
                              Last Name
                            </label>
                            <input
                              id="lastname"
                              className="form-control input-group-lg"
                              type="text"
                              name="lastname"
                              title="Enter Last Name"
                              placeholder="Last Name"
                              onChange={handleChange}
                              onBlur={handleBlur}
                              value={values.lastname}
                            />
                            {errors.lastname ? (
                              <span className="text-danger fs-6">
                                {errors.lastname}
                              </span>
                            ) : null}
                          </div>
                        </div>
                        <div className="row">
                          <div className="form-group col-md-6 col-sm-12">
                            <label htmlFor="Email ">Email</label>
                            <input
                              id="Mailing Address"
                              className="form-control input-group-lg"
                              type="text"
                              name="email"
                              title="Enter Email "
                              placeholder="Email "
                              onChange={handleChange}
                              onBlur={handleBlur}
                              value={values.email}
                            />
                            {errors.email ? (
                              <span className="text-danger fs-6">
                                {errors.email}
                              </span>
                            ) : null}
                          </div>
                          <div className="form-group col-md-6 col-sm-12">
                            <label htmlFor="Company Name" className="">
                              Company Name
                            </label>
                            <input
                              id="companyname"
                              className="form-control input-group-lg"
                              type="text"
                              name="companyname"
                              title="Enter Company Name"
                              placeholder="Company Name"
                              onChange={handleChange}
                              onBlur={handleBlur}
                              value={values.companyname}
                            />
                            {errors.companyname ? (
                              <span className="text-danger fs-6">
                                {errors.companyname}
                              </span>
                            ) : null}
                          </div>
                        </div>
                        <div className="row">
                          <div className="form-group col-md-6 col-sm-12">
                            <label htmlFor="jobtitle">Job Title </label>
                            <input
                              id="jobtitle"
                              className="form-control input-group-lg"
                              type="text"
                              name="jobtitle"
                              title="Enter Job Title"
                              placeholder="Job Title"
                              onChange={handleChange}
                              onBlur={handleBlur}
                              value={values.jobtitle}
                            />
                            {errors.jobtitle ? (
                              <span className="text-danger fs-6">
                                {errors.jobtitle}
                              </span>
                            ) : null}
                          </div>
                          <div className="form-group col-md-6 col-sm-12">
                            <label htmlFor="city" className="">
                              City
                            </label>
                            <input
                              id="city"
                              className="form-control input-group-lg"
                              type="text"
                              name="city"
                              title="Enter City"
                              placeholder="Enter City"
                              onChange={handleChange}
                              onBlur={handleBlur}
                              value={values.city}
                            />
                            {errors.city ? (
                              <span className="text-danger fs-6">
                                {errors.city}
                              </span>
                            ) : null}
                          </div>
                        </div>
                        <div className="row">
                          <div className="form-group col-md-6 col-sm-12">
                            <label htmlFor="provincestate">
                              Province/State{" "}
                            </label>
                            <input
                              id="provincestate"
                              className="form-control input-group-lg"
                              type="text"
                              name="provincestate"
                              title="Enter Province/State "
                              placeholder="Province/State "
                              onChange={handleChange}
                              onBlur={handleBlur}
                              value={values.provincestate}
                            />
                            {errors.provincestate ? (
                              <span className="text-danger fs-6">
                                {errors.provincestate}
                              </span>
                            ) : null}
                          </div>
                          <div className="form-group col-md-6 col-sm-12">
                            <label htmlFor="country" className="">
                              Country
                            </label>
                            <input
                              id="country"
                              className="form-control input-group-lg"
                              type="text"
                              name="country"
                              title="Enter Country "
                              placeholder="Phone Country "
                              onChange={handleChange}
                              onBlur={handleBlur}
                              value={values.country}
                            />
                            {errors.country ? (
                              <span className="text-danger fs-6">
                                {errors.country}
                              </span>
                            ) : null}
                          </div>
                        </div>
                        <div className="row">
                          <div className="form-group col-md-6 col-sm-12">
                            <label htmlFor="notification">Notification </label>
                            <input
                              id="notification"
                              className="form-control input-group-lg"
                              type="text"
                              name="notification"
                              title="Enter Notification  "
                              placeholder="Notification "
                              onChange={handleChange}
                              onBlur={handleBlur}
                              value={values.notification}
                            />
                            {errors.notification ? (
                              <span className="text-danger fs-6">
                                {errors.notification}
                              </span>
                            ) : null}
                          </div>
                          <div className="form-group col-md-6 col-sm-12">
                            <label htmlFor="subscriptionlevel" className="">
                              Subscription
                            </label>
                            <input
                              id="subscriptionlevel"
                              className="form-control input-group-lg"
                              type="text"
                              name="subscriptionlevel"
                              title="Enter Subscription  "
                              placeholder="Subscription  "
                              readOnly
                              value={subcription?.plan_name}
                            />
                          </div>
                        </div>
                        <div className="row">
                          <div className="form-group col-md-12 col-sm-12">
                            <label htmlFor="billinginformation">
                              Billing Information
                            </label>
                            <input
                              id="billinginformation"
                              className="form-control input-group-lg"
                              type="text"
                              name="billinginformation"
                              title="Enter Billing Information"
                              placeholder="Billing Information"
                              onChange={handleChange}
                              onBlur={handleBlur}
                              value={values.billinginformation}
                            />
                            {errors.billinginformation ? (
                              <span className="text-danger fs-6">
                                {errors.billinginformation}
                              </span>
                            ) : null}
                          </div>
                        </div>

                        <button type="submit" className="btn btn-primary">
                          {loader ? (
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                              }}
                            >
                              <Loader />
                            </div>
                          ) : (
                            "Save Changes"
                          )}
                        </button>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Layout>
    </>
  );
};

export default ProfileSubcriber;
