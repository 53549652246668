/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
import { useEffect, useState } from "react";
import "./subcription.css";
import StripeCheckout from "react-stripe-checkout";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import NewAxiosInstance from "../../https/newAxios";

function SubcriptionComponent() {
  const navigate = useNavigate();
  const tokens = localStorage.getItem("access_token");
  const [subcription, setSubcription] = useState([]);
  const [loader, setLoader] = useState(false);
  const user = JSON.parse(localStorage?.getItem("user"));
  
  const response = async () => {
      setLoader(true);
      const response = await NewAxiosInstance.get("/show-subscriptions")
      console.log(response?.data?.data);
      setSubcription(response.data?.data);
      setLoader(false);
  }

  useEffect(() => {
    response();
  }, []);


  const onToken = (token, id, price) => {
    setLoader(true);
    console.log(token);
    const formData = new FormData();
    formData.append("organization_id", user?.id);
    formData.append("subscription_id", id);
    formData.append("amount", price);
    formData.append("source", token?.id);
    console.log(token?.id, id, price, user?.id);
    NewAxiosInstance.post("organization/buy/package", formData).then((response) => {
      console.log(response);
      toast.success(response?.data?.message);
      setLoader(false);
      navigate("/");
    });
  };

  return (
    <>
      <>
        <main>
          <section
            className={`section-4 ${
              subcription?.length > 0 ? "auto-height" : "height-vh"
            }`}
          >
            <h1 className="text-70">Packages</h1>
            {loader ? (
              <h1 className="heading-h1 height-vh d-flex align-items-center justify-content-center">
                Loading...
              </h1>
            ) : subcription?.length > 0 ? (
              <>
                <div className="section-4-2">
                  <div className="section-main">
                    <div className="pricing-body">
                      <div className="pricing-body-plans">
                        <div className="active" id="pricing__monthly__plan">
                          <div>
                            {subcription?.map((data) => {
                              return (
                                <>
                                  <div className="card">
                                    <div className="card-header">
                                      <h2 className="card-price">
                                        {data?.price}
                                        <span>/{data?.duration_unit}</span>
                                      </h2>
                                      <p>{data?.plan_name}</p>
                                    </div>
                                    {tokens ? (
                                      <div className="card-footer">
                                        <StripeCheckout
                                          token={(token) =>
                                            onToken(
                                              token,
                                              data?.id,
                                              data?.price
                                            )
                                          }
                                          stripeKey="pk_test_51IIZO8Kp3j5Y1UYUhdopZryeRKjlRqEcw4rrDhwELKCRDjRSOJtSXicia6jkN63CyflKvQwej7SqGt1MZ6UwKgfz00ipRMArG9"
                                        >
                                          <a>Subscribe Now</a>
                                        </StripeCheckout>
                                      </div>
                                    ) : (
                                      <div
                                        className="card-footer"
                                        onClick={() =>
                                          navigate("/loginorganization")
                                        }
                                      >
                                        <a>Subscribe Now</a>
                                      </div>
                                    )}
                                  </div>
                                </>
                              );
                            })}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </>
            ) : (
              <h1 className="heading-h1">No Subcription</h1>
            )}
          </section>
        </main>
      </>
    </>
  );
}

export default SubcriptionComponent;
