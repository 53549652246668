import * as Yup from "yup";

export const validationSchemaSubcribe = Yup.object({
  firstname: Yup.string().required("First Name is required"),
  lastname: Yup.string().required("Last Name is required"),
  email: Yup.string()
    .email("Invalid email address")
    .required("Email is required"),
  password: Yup.string().required("Password is required"),
  notification: Yup.string().required("Notification is required"),
  subscriptionlevel: Yup.string().required("Subscription Level is required"),
});

export const initialValuesSubcribe = {
  firstname: "",
  lastname: "",
  email: "",
  password: "",
  notification: "",
  subscriptionlevel: "",
};

