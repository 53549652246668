import Swipers from "../home/Swiper/Swipers";
import Layout from "../layout/Layout";
import SubcriptionComponent from "../subcription/SubcriptionComponent";

function Subcription() {
  return (
    <>
      <Layout>
        <Swipers />
        <SubcriptionComponent />
      </Layout>
    </>
  );
}

export default Subcription;
