/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable jsx-a11y/anchor-is-valid */
import { Link } from "react-router-dom";

function Footer() {
  return (
    <footer id="footer">
      <div className="container">
        <div className="row">
          <div className="footer-wrapper">
            <div className="col-md-3 col-sm-3">
              <a href="#">
                <img
                  src="/images/logo.png"
                  style={{ width: "80px", height: "80px" }}
                  alt="logo"
                />
              </a>
              <ul className="list-inline social-icons">
                <li style={{ width: "30%" }}>
                  <a
                    href="https://www.linkedin.com/company/indstree-research-data-inc/about/"
                    target="_blank"
                  >
                    <i
                      className="icon ion-social-linkedin"
                      style={{ fontSize: "20px" }}
                    />
                  </a>
                </li>
              </ul>
            </div>
            <div className="col-md-2 col-sm-2">
              <h5>For subscribers</h5>
              <ul className="footer-links">
                <li>
                  <Link to={"/signupsubcriber"}>Sign up </Link>
                </li>
                <li>
                  <Link to={"/loginsubcriber"}>Log in</Link>
                </li>
                <li>
                  <a href="#">Learn more</a>
                </li>
              </ul>
            </div>
            <div className="col-md-2 col-sm-2">
              <h5>For organizations</h5>
              <ul className="footer-links">
                <li>
                  <Link to={"/signuporganization"}>Sign up </Link>
                </li>
                <li>
                  <Link to={"/loginorganization"}>Log in</Link>
                </li>
                <li>
                  <a href="#">Learn more</a>
                </li>
              </ul>
            </div>
            <div className="col-md-2 col-sm-2">
              <h5>About</h5>
              <ul className="footer-links">
                <li>
                  <a href="#">Indstree</a>
                </li>
                <li>
                  <Link to={"/contact"}>Contact</Link>
                </li>
                <li>
                  <a href="#">Privacy Policy</a>
                </li>
                <li>
                  <a href="#">Terms</a>
                </li>
              </ul>
            </div>
            <div className="col-md-3 col-sm-3">
              <h5>Contact Us</h5>
              <ul className="contact">
                <li>
                  <i className="icon ion-ios-telephone-outline" />
                  <a href="tel:+604.690.3561" className="cursor-pointer">
                  604.690.3561
                  </a>
                </li>
                <li>
                  <i className="icon ion-ios-email-outline" />
                  <a href="mailto:hello@indstree.com">hello@indstree.com</a>
                </li>
                <li style={{ display: "flex" }}>
                  <i className="icon ion-ios-location-outline" />
                  <p>
                    Suite 250 - #939 997 Seymour St. Vancouver, BC, Canada V6B
                    3M1
                  </p>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div className="copyright">
        <p className="footer-p">
          copyright @hnhtechsolutions 2024. All rights reserved
        </p>
      </div>
    </footer>
  );
}
export default Footer;
