/* eslint-disable jsx-a11y/img-redundant-alt */
/* eslint-disable eqeqeq */
/* eslint-disable jsx-a11y/anchor-is-valid */
import { useFormik } from "formik";
import { NavLink } from "react-router-dom";
import { useState } from "react";
import {
  initialValuesSubcribe,
  initialValuesOraganizer,
  validationSchemaOraganizer,
  validationSchemaSubcribe,
} from "./schema";

function Signup() {
  const [show, setShow] = useState(true);
  const [logo, setLogo] = useState(null);
  const [headerPhoto, setHeaderPhoto] = useState(null);

  const {
    values,
    errors,
    handleBlur,
    handleChange,
    handleSubmit,
    touched,
    resetForm,
  } = useFormik({
    initialValues: show ? initialValuesSubcribe : initialValuesOraganizer,
    validationSchema: show
      ? validationSchemaSubcribe
      : validationSchemaOraganizer,
    onSubmit: (values) => {
      console.log(values, "values");
      console.log(logo, headerPhoto);
      resetForm();
    },
  });

  const handleLogoChange = (event) => {
    const file = event.target.files[0];
    setLogo(file);
  };

  const handleHeaderPhotoChange = (event) => {
    const file = event.target.files[0];
    setHeaderPhoto(file);
  };

  const handleRemoveLogo = () => {
    setLogo(null);
  };

  const handleRemoveHeaderPhoto = () => {
    setHeaderPhoto(null);
  };

  const handleUploadLogo = () => {
    document.getElementById("logoInput").click();
  };

  const handleUploadHeaderPhoto = () => {
    document.getElementById("headerPhotoInput").click();
  };

  return (
    <>
      <section
        id="banner1"
        style={{
          height: "150vh",
          backgroundImage: 'url("images/banner1.png")',
        }}
      >
        <div className="container">
          {/* Sign Up Form
  ================================================= */}
          {show ? (
            <div className="sign-up-form">
              <div className="d-flex-col" style={{ gap: "20px" }}>
                <div>
                  <button
                    type="submit"
                    className="btn-secondary tab"
                    onClick={() => setShow(true)}
                    style={{ background: show ? "#336230" : null }}
                  >
                    Subscriber
                  </button>
                </div>
                <div>
                  <button
                    type="submit"
                    className="btn-secondary tab"
                    onClick={() => setShow(false)}
                    style={{ background: !show ? "#336230" : null }}
                  >
                    Organization
                  </button>
                </div>
              </div>
              <h2 style={{ margin: "30px" }} className="text-white">
                Welcome to Indstree
              </h2>
              <div className="line-divider" />
              <div className="form-wrapper">
                <form onSubmit={handleSubmit}>
                  <div className="row flex">
                    <fieldset className="form-group">
                      <input
                        type="firstname"
                        className="form-control"
                        id="firstname"
                        name="firstname"
                        placeholder="Enter First Name"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.firstname}
                      />
                      {errors.firstname && touched.firstname ? (
                        <span className="text-danger fs-6">
                          {errors.firstname}
                        </span>
                      ) : null}
                    </fieldset>
                    <fieldset className="form-group">
                      <input
                        type="text"
                        className="form-control"
                        id="lastname"
                        name="lastname"
                        placeholder="Enter Last Name"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.lastname}
                      />
                      {errors.lastname && touched.lastname ? (
                        <span className="text-danger fs-6">
                          {errors.lastname}
                        </span>
                      ) : null}
                    </fieldset>
                  </div>
                  <div className="row flex">
                    <fieldset className="form-group">
                      <input
                        type="email"
                        className="form-control"
                        id="email"
                        name="email"
                        placeholder="Enter Email"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.email}
                      />
                      {errors.email && touched.email ? (
                        <span className="text-danger fs-6">{errors.email}</span>
                      ) : null}
                    </fieldset>
                    <fieldset className="form-group">
                      <input
                        type="text"
                        className="form-control"
                        id="companyname"
                        name="companyname"
                        placeholder="Enter a Company Name"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.companyname}
                      />
                      {errors.companyname && touched.companyname ? (
                        <span className="text-danger fs-6">
                          {errors.companyname}
                        </span>
                      ) : null}
                    </fieldset>
                  </div>
                  <div className="row flex">
                    <fieldset className="form-group">
                      <input
                        type="text"
                        className="form-control"
                        id="jobtitle"
                        name="jobtitle"
                        placeholder="Enter Job Title"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.jobtitle}
                      />
                      {errors.jobtitle && touched.jobtitle ? (
                        <span className="text-danger fs-6">
                          {errors.jobtitle}
                        </span>
                      ) : null}
                    </fieldset>
                    <fieldset className="form-group">
                      <input
                        type="text"
                        className="form-control"
                        id="city"
                        name="city"
                        placeholder="Enter City"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.city}
                      />
                      {errors.city && touched.city ? (
                        <span className="text-danger fs-6">{errors.city}</span>
                      ) : null}
                    </fieldset>
                  </div>

                  <div className="row flex">
                    <fieldset className="form-group">
                      <input
                        type="text"
                        className="form-control"
                        id="provincestate"
                        name="provincestate"
                        placeholder="Enter Province/State"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.provincestate}
                      />
                      {errors.provincestate && touched.provincestate ? (
                        <span className="text-danger fs-6">
                          {errors.provincestate}
                        </span>
                      ) : null}
                    </fieldset>
                    <fieldset className="form-group">
                      <input
                        type="text"
                        className="form-control"
                        id="country"
                        name="country"
                        placeholder="Enter a Country"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.country}
                      />
                      {errors.country && touched.country ? (
                        <span className="text-danger fs-6">
                          {errors.country}
                        </span>
                      ) : null}
                    </fieldset>
                  </div>
                  <div className="row flex">
                    <fieldset className="form-group">
                      <input
                        type="text"
                        className="form-control"
                        id="notification"
                        name="notification"
                        placeholder="Enter Notification"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.notification}
                      />
                      {errors.notification && touched.notification ? (
                        <span className="text-danger fs-6">
                          {errors.notification}
                        </span>
                      ) : null}
                    </fieldset>
                    <fieldset className="form-group">
                      <input
                        type="text"
                        className="form-control"
                        id="subscriptionlevel"
                        name="subscriptionlevel"
                        placeholder="Enter Subscription level - Basic or Premium"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.subscriptionlevel}
                      />
                      {errors.subscriptionlevel && touched.subscriptionlevel ? (
                        <span className="text-danger fs-6">
                          {errors.subscriptionlevel}
                        </span>
                      ) : null}
                    </fieldset>
                  </div>
                  <div className="row flex">
                    <fieldset className="form-group">
                      <input
                        type="text"
                        className="form-control"
                        id="billinginformation"
                        name="billinginformation"
                        placeholder="Enter Billing information"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.billinginformation}
                      />
                      {errors.billinginformation &&
                      touched.billinginformation ? (
                        <span className="text-danger fs-6">
                          {errors.billinginformation}
                        </span>
                      ) : null}
                    </fieldset>
                  </div>
                  <button type="submit" className="btn-secondary">
                    Sign up
                  </button>
                </form>
              </div>
              <NavLink to={"/"}>New to Indstree? Sign in account</NavLink>
              <img
                className="form-shadow"
                src="images/bottom-shadow.png"
                alt=""
              />
            </div>
          ) : (
            <div className="sign-up-form">
              {console.log(errors?.firstNationName, "errors")}
              <div className="d-flex-col" style={{ gap: "20px" }}>
                <div>
                  <button
                    type="submit"
                    className="btn-secondary tab"
                    onClick={() => setShow(true)}
                    style={{ background: show ? "#336230" : null }}
                  >
                    Subscriber
                  </button>
                </div>
                <div>
                  <button
                    type="submit"
                    className="btn-secondary tab"
                    onClick={() => setShow(false)}
                    style={{ background: !show ? "#336230" : null }}
                  >
                    Organization
                  </button>
                </div>
              </div>
              <h2 style={{ margin: "30px" }} className="text-white">
                Welcome to Indstree
              </h2>
              <div className="line-divider" />
              <div className="form-wrapper">
                <form onSubmit={handleSubmit}>
                  <div className="row flex">
                    <div className="logo-and-header-photo-uploader">
                      <div>
                        <div className="upload-button">
                          <button
                            onClick={handleUploadLogo}
                            className="btn btn-primary w-200"
                          >
                            Upload Logo
                          </button>
                          <input
                            type="file"
                            id="logoInput"
                            accept="image/*"
                            style={{ display: "none" }}
                            onChange={handleLogoChange}
                          />
                        </div>
                        {logo && (
                          <div className="preview">
                            <img
                              src={URL.createObjectURL(logo)}
                              alt="Logo Preview"
                              className="rounded-circle"
                            />
                            <button
                              onClick={handleRemoveLogo}
                              className="btn btn-danger w-200"
                            >
                              Remove
                            </button>
                          </div>
                        )}
                      </div>
                      <div>
                        <div className="upload-button">
                          <button
                            onClick={handleUploadHeaderPhoto}
                            className="btn btn-primary w-200"
                          >
                            Upload Header Photo
                          </button>
                          <input
                            type="file"
                            id="headerPhotoInput"
                            accept="image/*"
                            style={{ display: "none" }}
                            onChange={handleHeaderPhotoChange}
                          />
                        </div>
                        {headerPhoto && (
                          <div className="preview">
                            <img
                              src={URL.createObjectURL(headerPhoto)}
                              alt="Header Photo Preview"
                              className="rounded-circle"
                            />
                            <button
                              onClick={handleRemoveHeaderPhoto}
                              className="btn btn-danger w-200"
                            >
                              Remove
                            </button>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="row flex">
                    <fieldset className="form-group">
                      <input
                        type="text"
                        className="form-control"
                        id="firstNationName"
                        name="firstNationName"
                        placeholder="Enter First Nation Name"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.firstNationName}
                      />
                      {console.log(touched.firstNationName, "errors")}
                      {errors.firstNationName ? (
                        <span className="text-danger fs-6">
                          {errors.firstNationName}
                        </span>
                      ) : null}
                    </fieldset>
                    <fieldset className="form-group">
                      <input
                        type="text"
                        className="form-control"
                        id="website"
                        name="website"
                        placeholder="Enter Website URL"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.website}
                      />
                      {errors.website ? (
                        <span className="text-danger fs-6">
                          {errors.website}
                        </span>
                      ) : null}
                    </fieldset>
                  </div>
                  <div className="row flex">
                    <fieldset className="form-group">
                      <input
                        type="text"
                        className="form-control"
                        id="mailingAddress"
                        name="mailingAddress"
                        placeholder="Enter Mailing Address"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.mailingAddress}
                      />
                      {errors.mailingAddress ? (
                        <span className="text-danger fs-6">
                          {errors.mailingAddress}
                        </span>
                      ) : null}
                    </fieldset>
                    <fieldset className="form-group">
                      <input
                        type="text"
                        className="form-control"
                        id="city"
                        name="city1"
                        placeholder="Enter City"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.city1}
                      />
                      {errors.city1 ? (
                        <span className="text-danger fs-6">{errors.city1}</span>
                      ) : null}
                    </fieldset>
                  </div>
                  <div className="row flex">
                    <fieldset className="form-group">
                      <input
                        type="text"
                        className="form-control"
                        id="province"
                        name="province"
                        placeholder="Enter Province"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.province}
                      />
                      {errors.province ? (
                        <span className="text-danger fs-6">
                          {errors.province}
                        </span>
                      ) : null}
                    </fieldset>
                    <fieldset className="form-group">
                      <input
                        type="text"
                        className="form-control"
                        id="postalCode"
                        name="postalCode"
                        placeholder="Enter Postal Code"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.postalCode}
                      />
                      {errors.postalCode ? (
                        <span className="text-danger fs-6">
                          {errors.postalCode}
                        </span>
                      ) : null}
                    </fieldset>
                  </div>
                  <div className="row flex">
                    <fieldset className="form-group">
                      <input
                        type="text"
                        className="form-control"
                        id="country"
                        name="country1"
                        placeholder="Enter Country"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.country1}
                      />
                      {errors.country1 ? (
                        <span className="text-danger fs-6">
                          {errors.country1}
                        </span>
                      ) : null}
                    </fieldset>
                    <fieldset className="form-group">
                      <input
                        type="text"
                        className="form-control"
                        id="phoneNumber"
                        name="phoneNumber"
                        placeholder="Enter Phone Number"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.phoneNumber}
                      />
                      {errors.phoneNumber ? (
                        <span className="text-danger fs-6">
                          {errors.phoneNumber}
                        </span>
                      ) : null}
                    </fieldset>
                  </div>
                  <div className="row flex">
                    <fieldset className="form-group">
                      <input
                        type="text"
                        className="form-control"
                        id="treaty"
                        name="treaty"
                        placeholder="Enter Treaty"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.treaty}
                      />
                      {errors.treaty ? (
                        <span className="text-danger fs-6">
                          {errors.treaty}
                        </span>
                      ) : null}
                    </fieldset>
                    <fieldset className="form-group">
                      <input
                        type="text"
                        className="form-control"
                        id="tribalCouncil"
                        name="tribalCouncil"
                        placeholder="Enter Tribal Council"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.tribalCouncil}
                      />
                      {errors.tribalCouncil ? (
                        <span className="text-danger fs-6">
                          {errors.tribalCouncil}
                        </span>
                      ) : null}
                    </fieldset>
                  </div>
                  <div className="row flex">
                    <fieldset className="form-group">
                      <input
                        type="text"
                        className="form-control"
                        id="physicalLocation"
                        name="physicalLocation"
                        placeholder="Enter Physical Location"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.physicalLocation}
                      />
                      {errors.physicalLocation ? (
                        <span className="text-danger fs-6">
                          {errors.physicalLocation}
                        </span>
                      ) : null}
                    </fieldset>
                    <fieldset className="form-group">
                      <input
                        type="text"
                        className="form-control"
                        id="language"
                        name="language"
                        placeholder="Enter Language"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.language}
                      />
                      {errors.language ? (
                        <span className="text-danger fs-6">
                          {errors.language}
                        </span>
                      ) : null}
                    </fieldset>
                  </div>
                  <div className="row flex">
                    <fieldset className="form-group">
                      <input
                        type="text"
                        className="form-control"
                        id="onReserveMembership"
                        name="onReserveMembership"
                        placeholder="Enter On-reserve Membership"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.onReserveMembership}
                      />
                      {errors.onReserveMembership ? (
                        <span className="text-danger fs-6">
                          {errors.onReserveMembership}
                        </span>
                      ) : null}
                    </fieldset>
                    <fieldset className="form-group">
                      <input
                        type="text"
                        className="form-control"
                        id="offReserveMembership"
                        name="offReserveMembership"
                        placeholder="Enter Off-reserve Membership"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.offReserveMembership}
                      />
                      {errors.offReserveMembership ? (
                        <span className="text-danger fs-6">
                          {errors.offReserveMembership}
                        </span>
                      ) : null}
                    </fieldset>
                  </div>
                  <div className="row flex">
                    <fieldset className="form-group">
                      <input
                        type="text"
                        className="form-control"
                        id="chief"
                        name="chief"
                        placeholder="Enter Chief"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.chief}
                      />
                      {errors.chief ? (
                        <span className="text-danger fs-6">{errors.chief}</span>
                      ) : null}
                    </fieldset>
                    <fieldset className="form-group">
                      <input
                        type="text"
                        className="form-control"
                        id="councillors"
                        name="councillors"
                        placeholder="Enter Councillors"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.councillors}
                      />
                      {errors.councillors ? (
                        <span className="text-danger fs-6">
                          {errors.councillors}
                        </span>
                      ) : null}
                    </fieldset>
                  </div>
                  <div className="row flex">
                    <fieldset className="form-group">
                      <input
                        type="text"
                        className="form-control"
                        id="term"
                        name="term"
                        placeholder="Enter Term (years)"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.term}
                      />
                      {errors.term ? (
                        <span className="text-danger fs-6">{errors.term}</span>
                      ) : null}
                    </fieldset>
                    <fieldset className="form-group">
                      <input
                        type="date"
                        className="form-control"
                        id="electionDate"
                        name="electionDate"
                        placeholder="Enter Election Date"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.electionDate}
                      />
                      {errors.electionDate ? (
                        <span className="text-danger fs-6">
                          {errors.electionDate}
                        </span>
                      ) : null}
                    </fieldset>
                  </div>
                  <div className="row flex">
                    <fieldset className="form-group">
                      <input
                        type="text"
                        className="form-control"
                        id="economicDevelopmentCorpName"
                        name="economicDevelopmentCorpName"
                        placeholder="Enter Economic Development Corp Name"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.economicDevelopmentCorpName}
                      />
                      {errors.economicDevelopmentCorpName ? (
                        <span className="text-danger fs-6">
                          {errors.economicDevelopmentCorpName}
                        </span>
                      ) : null}
                    </fieldset>
                    <fieldset className="form-group">
                      <input
                        type="text"
                        className="form-control"
                        id="economicDevelopmentCorpWebsite"
                        name="economicDevelopmentCorpWebsite"
                        placeholder="Enter Economic Development Corp Website"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.economicDevelopmentCorpWebsite}
                      />
                      {errors.economicDevelopmentCorpWebsite ? (
                        <span className="text-danger fs-6">
                          {errors.economicDevelopmentCorpWebsite}
                        </span>
                      ) : null}
                    </fieldset>
                  </div>
                  <div className="row flex">
                    <fieldset className="form-group">
                      <input
                        type="text"
                        className="form-control"
                        id="existingCompanies"
                        name="existingCompanies"
                        placeholder="Enter Existing Companies"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.existingCompanies}
                      />
                      {errors.existingCompanies ? (
                        <span className="text-danger fs-6">
                          {errors.existingCompanies}
                        </span>
                      ) : null}
                    </fieldset>
                    <fieldset className="form-group">
                      <input
                        type="text"
                        className="form-control"
                        id="equityInvestments"
                        name="equityInvestments"
                        placeholder="Enter Equity Investments"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.equityInvestments}
                      />
                      {errors.equityInvestments ? (
                        <span className="text-danger fs-6">
                          {errors.equityInvestments}
                        </span>
                      ) : null}
                    </fieldset>
                  </div>
                  <div className="row flex">
                    <fieldset className="form-group">
                      <input
                        type="text"
                        className="form-control"
                        id="partners"
                        name="partners"
                        placeholder="Enter Partners"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.partners}
                      />
                      {errors.partners ? (
                        <span className="text-danger fs-6">
                          {errors.partners}
                        </span>
                      ) : null}
                    </fieldset>
                    <fieldset className="form-group">
                      <input
                        type="text"
                        className="form-control"
                        id="news"
                        name="news"
                        placeholder="Enter News"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.news}
                      />
                      {errors.news ? (
                        <span className="text-danger fs-6">{errors.news}</span>
                      ) : null}
                    </fieldset>
                  </div>

                  <button type="submit" className="btn-secondary">
                    Sign up
                  </button>
                </form>
              </div>
              <NavLink to={"/"}>New to Indstree? Sign in account</NavLink>
              <img
                className="form-shadow"
                src="images/bottom-shadow.png"
                alt=""
              />
            </div>
          )}
        </div>
      </section>
    </>
  );
}

export default Signup;
