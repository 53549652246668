/* eslint-disable no-unused-vars */
/* eslint-disable jsx-a11y/anchor-is-valid */
import * as Yup from "yup";
import React, { useState } from 'react'
import { NavLink, useNavigate } from 'react-router-dom'
import axiosInstance from "../../https/axios";
import { useFormik } from "formik";
import { toast } from "react-toastify";
import { Loader } from "../loader/Loader";
import Swipers from "./Swiper/Swipers";
import NewSection from "./NewSection";


const Header = () => {
  const navigate = useNavigate();
  const token = localStorage.getItem('access_token');
  const [loader, setLoader] = useState(false);
  const signupSchema = Yup.object().shape({
    name: Yup.string().required("name is required"),
    email: Yup.string().email("Invalid email").required("email is required"),
    password: Yup.string().required("password is required"),
    password_confirmation: Yup.string()
      .required("password is required")
      .oneOf([Yup.ref("password"), null], "Password must match"),
  });

  const initialValues = {
    name: "",
    email: "",
    password: "",
    password_confirmation: "",
    role: 'user'
  };

  const {
    values,
    errors,
    handleBlur,
    handleChange,
    handleSubmit,
    touched,
    resetForm,
  } = useFormik({
    initialValues: initialValues,
    validationSchema: signupSchema,
    onSubmit: (values) => {
      setLoader(true);
      console.log(values);
      axiosInstance
        .post("register", values)
        .then((data) => {
          console.log(data, 'data');
          resetForm();
          toast.success("Signup Successfully !");
          navigate('/login');
          setLoader(false);
        })
        .catch((err) => {
          console.log(err);
          toast.error(err, {
            position: "bottom-left",
          });
          setLoader(false);
        });
    },
  });


  return (
    <section>
      <div>
        <Swipers />
        <NewSection />
      </div>
    </section>

  )
}

export default Header