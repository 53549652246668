/* eslint-disable no-unused-vars */
/* eslint-disable eqeqeq */
/* eslint-disable jsx-a11y/anchor-is-valid */
import * as Yup from "yup";
import { useFormik } from "formik";
import { NavLink, useNavigate } from "react-router-dom";
import axiosInstance from "../../../https/axios";
import { useState } from "react";
import { toast } from "react-toastify";
import { Loader } from "../../loader/Loader";
import { BiHide, BiShow, BiSolidHide } from "react-icons/bi";
import NewAxiosInstance from "../../../https/newAxios";

function LoginOrganization() {
  const navigate = useNavigate();
  const [loader, setLoader] = useState(false);
  const [showPassword, setShowPassword] = useState(false);

  const signupSchema = Yup.object().shape({
    mailing_address: Yup.string()
      .email("Invalid email")
      .required("Email is required"),
    password: Yup.string().required("Password is required"),
  });

  const initialValues = {
    mailing_address: "",
    password: "",
  };

  const { values, errors, touched, handleChange, handleBlur , handleSubmit } = useFormik({
    initialValues,
    validationSchema: signupSchema,
    onSubmit: () => { 
      setLoader(true);
      try {
        NewAxiosInstance.post("organization/login", values).then((response) => {
          console.log(response.data);
          if (response.data.status === true) {
            localStorage.setItem("access_token", response.data.token);
            const user = response.data.data;
            localStorage.setItem("user", JSON.stringify(user));
            window.location.href = "/profileorganization"
            setLoader(false);
          } else {
            toast.error(response.data.message);
            setLoader(false);
          }
        });
      } catch (error) {
        console.error("Error logging in:", error);
        toast.error(error.message, { position: "bottom-left" });
        setLoader(false);
      }
    }
  });

 

  return (
    <>
      <section
        id="banner1"
        style={{ backgroundImage: 'url("images/banner1.png")' }}
      >
        <div className="container">
          {/* Sign Up Form
  ================================================= */}
          <div className="sign-up-form">
            <h2 className="text-white">Welcome to Indstree</h2>
            <div className="line-divider" />
            <div className="form-wrapper">
              <p className="signup-text">Intelligence. Verified.</p>
              <form onSubmit={handleSubmit}>
                <fieldset className="form-group">
                <label htmlFor="" className="d-flex justify-content-start" style={{marginLeft:'10px' , fontSize:'16px' , fontWeight: 600}}>Email</label>
                  <input
                    type="email"
                    className="form-control"
                    id="mailing_address"
                    name="mailing_address"
                    placeholder="Enter email"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.mailing_address}
                  />
                  {errors.mailing_address && touched.mailing_address && (
                    <span className="text-danger fs-6">
                      {errors.mailing_address}
                    </span>
                  )}
                </fieldset>
                <fieldset className="form-group cursor-pointer">
                <label htmlFor="" className="d-flex justify-content-start" style={{marginLeft:'10px' , fontSize:'16px' , fontWeight: 600}}>Password</label>
                  <input
                    type={showPassword ? "text" : "password"}
                    className="form-control"
                    id="password"
                    name="password"
                    placeholder="Enter a password"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.password}
                  />
                  <div className="eye">
                    {showPassword ? (
                      <BiShow
                        onClick={() => setShowPassword(false)}
                        color="black"
                        size={20}
                      />
                    ) : (
                      <BiHide
                        onClick={() => setShowPassword(true)}
                        size={20}
                        color="black"
                      />
                    )}
                  </div>
                  {errors.password && touched.password && (
                    <span className="text-danger fs-6">{errors.password}</span>
                  )}
                </fieldset>
                <NavLink to={"/forgot-password"}>Forgot Password</NavLink>{" "}
                <br />
                <button type="submit" className="btn-secondary">
                  {loader ? <Loader /> : "Sign in"}
                </button>
              </form>
            </div>
            <NavLink to={"/signuporganization"}>
              New to Indstree? Create an account
            </NavLink>
            <img
              className="form-shadow"
              src="images/bottom-shadow.png"
              alt=""
            />
          </div>
        </div>
      </section>
    </>
  );
}

export default LoginOrganization;
