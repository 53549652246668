/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
/* eslint-disable jsx-a11y/img-redundant-alt */
/* eslint-disable no-dupe-keys */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect } from "react";
import * as Yup from "yup";
import Layout from "../layout/Layout";
import { useFormik } from "formik";
import NewAxiosInstance from "../../https/newAxios";
import { toast } from "react-toastify";
import { Loader } from "../loader/Loader";
import moment from 'moment';
// import { BiHide, BiShow } from "react-icons/bi";

const ProfileOraganization = () => {
  const user = JSON.parse(localStorage?.getItem("user"));
  const token = localStorage?.getItem("access_token");
  const [logo, setLogo] = useState(user?.logo);
  const [headerPhoto, setHeaderPhoto] = useState(user?.header_photo);
  const [logo1, setLogo1] = useState(null);
  const [headerPhoto1, setHeaderPhoto1] = useState(null);
  const [Activity, setActivity] = useState([]);
  const [loader, setLoader] = useState(false);

  const initialValuesOraganizer = {
    firstNationName: user?.first_nation,
    website: user?.website_url,
    mailingAddress: user?.mailing_address,
    city1: user?.city,
    province: user?.province,
    postalCode: user?.postal_code,
    country1: user?.country,
    phoneNumber: user?.phone_no,
    treaty: user?.treaty,
    tribalCouncil: user?.tribal_council,
    physicalLocation: user?.physical_location,
    language: user?.language,
    onReserveMembership: user?.on_reserve_membership,
    offReserveMembership: user?.off_reserve_membership,
    chief: user?.chief,
    councillors: user?.councillor,
    term: user?.term,
    electionDate: user?.election_date,
    economicDevelopmentCorpName: user?.economic_development_corp,
    economicDevelopmentCorpWebsite: user?.ec_dev_corp_website,
    existingCompanies: user?.existing_company,
    equityInvestments: user?.equity_investment,
    partners: user?.partner,
    news: user?.news,
  };

  const validationSchemaOraganizer = Yup.object({
    firstNationName: Yup.string().required("First Nation Name is required"),
    website: Yup.string()
      .url("Invalid URL format")
      .required("Website URL is required"),
    mailingAddress: Yup.string().required("Mailing Address is required"),
    city1: Yup.string().required("City is required"),
    province: Yup.string().required("Province is required"),
    postalCode: Yup.string().required("Postal Code is required"),
    country1: Yup.string().required("Country is required"),
    phoneNumber: Yup.number().required("Phone Number is required"),
    treaty: Yup.string().min(1).max(1).required("Treaty is required"),
    tribalCouncil: Yup.string().required("Tribal Council is required"),
    physicalLocation: Yup.string().required("Physical Location is required"),
    language: Yup.string().required("Language is required"),
    onReserveMembership: Yup.string().required(
      "On-reserve Membership is required"
    ),
    offReserveMembership: Yup.string().required(
      "Off-reserve Membership is required"
    ),
    chief: Yup.string().required("Chief is required"),
    councillors: Yup.string().required("Councillors is required"),
    term: Yup.string().required("Term is required"),
    electionDate: Yup.string().required("Election Date is required"),
    economicDevelopmentCorpName: Yup.string().required(
      "Economic Development Corp Name is required"
    ),
    economicDevelopmentCorpWebsite: Yup.string()
      .url("Invalid URL format")
      .required("Economic Development Corp Website URL is required"),
    existingCompanies: Yup.string().required("Existing Companies is required"),
    equityInvestments: Yup.string().required("Equity Investments is required"),
    partners: Yup.string().required("Partners is required"),
    news: Yup.string().required("News is required"),
  });

  const { values, errors, handleBlur, handleChange, handleSubmit, resetForm } =
    useFormik({
      initialValues: initialValuesOraganizer,
      validationSchema: validationSchemaOraganizer,
      onSubmit: (values) => {
        setLoader(true);
        const formData = new FormData();
        formData.append("logo", logo);
        formData.append("header_photo", headerPhoto);
        formData.append("first_nation", values.firstNationName);
        formData.append("website_url", values.website);
        formData.append("mailing_address", values.mailingAddress);
        formData.append("city", values.city1);
        formData.append("province", values.province);
        formData.append("postal_code", values.postalCode);
        formData.append("country", values.country1);
        formData.append("phone_no", values.phoneNumber);
        formData.append("treaty", values.treaty);
        formData.append("tribal_council", values.tribalCouncil);
        formData.append("physical_location", values.physicalLocation);
        formData.append("language", values.language);
        formData.append("on_reserve_membership", values.onReserveMembership);
        formData.append("off_reserve_membership", values.offReserveMembership);
        formData.append("chief", values.chief);
        formData.append("councillor", values.councillors);
        formData.append("term", values.term);
        formData.append("election_date", values.electionDate);
        formData.append(
          "economic_development_corp",
          values.economicDevelopmentCorpName
        );
        formData.append(
          "ec_dev_corp_website",
          values.economicDevelopmentCorpWebsite
        );
        formData.append("existing_company", values.existingCompanies);
        formData.append("equity_investment", values.equityInvestments);
        formData.append("partner", values.partners);
        formData.append("news", values.news);
        NewAxiosInstance.post(`organization/profile/${user?.id}`, formData)
          .then((response) => {
            console.log("Response:", response);
            toast.success(response?.data?.message);
            const user = response?.data?.data;
            localStorage.setItem("user", JSON.stringify(user));
            console.log(localStorage.setItem("user", JSON.stringify(user)));
            setLoader(false);
            window.location.reload();
            resetForm();
          })
          .catch((error) => {
            console.error("Error:", error);
            setLoader(false);
            toast.error("An error occurred while updating profile");
          });
      },
    });

  const handleLogoChange = (event) => {
    const file = event.target.files[0];
    setLogo1(file);
    setLogo(file);
  };

  const handleHeaderPhotoChange = (event) => {
    const file = event.target.files[0];
    setHeaderPhoto1(file);
    setHeaderPhoto(file);
  };

  const handleRemoveLogo = () => {
    setLogo1(null);
  };

  const handleRemoveHeaderPhoto = () => {
    setHeaderPhoto1(null);
  };

  const handleUploadLogo = () => {
    document.getElementById("logoInput").click();
  };

  const handleUploadHeaderPhoto = () => {
    document.getElementById("headerPhotoInput").click();
  };

  const handleActivity = () => {
    setLoader(true);
    // e.preventDefault()
    NewAxiosInstance.get(`organization/activity/view/${user?.id}`)
      .then((data) => {
        console.log(data, "activivty");
        if (data.data?.status === true) {
          setLoader(false);
          setActivity(data.data.data);
        } else {
          console.log("error")
          toast.success(data?.data?.message);
          setLoader(false);
        }
      })
      .catch((err) => {
        console.log(err);
        setLoader(false);
      });


  };
  useEffect(() => {
    handleActivity()
  }, [])

  return (
    <>
      <Layout>
        <div className="container">
          {/* Timeline================================================= */}
          <div className="timeline">
            <div
              className="timeline-cover"
              style={{
                backgroundImage: `url('${user?.header_photo}')`,
                backgroundColor: "#F8F8F8",
              }}
            >
              {/*Timeline Menu for Large Screens*/}
              <div className="timeline-nav-bar hidden-sm hidden-xs">
                <div className="row">
                  <div className="col-md-3">
                    <div className="profile-info">
                      <img
                        src={user?.logo} // Assuming user.id is the unique identifier for each user
                        alt=""
                        className="img-responsive profile-photo"
                        style={{ backgroundColor: "#F8F8F8" }}
                      />
                      <h3>{user?.first_nation}</h3>
                    </div>
                  </div>
                </div>
              </div>
              {/*Timeline Menu for Large Screens End*/}
              {/*Timeline Menu for Small Screens*/}
              <div className="navbar-mobile hidden-lg hidden-md">
                <div className="profile-info">
                  <img
                    src={user?.logo} // Assuming user.id is the unique identifier for each user
                    alt="logo"
                    className="img-responsive profile-photo"
                  />
                  <h4>{user?.first_nation}</h4>
                </div>
              </div>
              {/*Timeline Menu for Small Screens End*/}
            </div>
            <div id="page-contents">
              <div className="row mt-40">
                <div className="col-md-8">
                  {/* Basic Information
        ================================================= */}
                  <div className="edit-profile-container">
                    <div className="block-title">
                      <h4 className="grey">
                        <i className="icon ion-android-checkmark-circle" />
                        Profile Information
                      </h4>
                      <div className="line" />
                      <p>
                        At vero eos et accusamus et iusto odio dignissimos
                        ducimus qui blanditiis praesentium voluptatum deleniti
                        atque corrupti quos dolores et quas molestias excepturi
                        sint occaecati cupiditate
                      </p>
                      <div className="line" />
                    </div>
                    <div className="edit-block">
                      <div className="row flex">
                        <div
                          className="logo-and-header-photo-uploader w-100"
                          style={{
                            justifyContent: "space-around",
                            margin: "20px 0px",
                          }}
                        >
                          <div>
                            <div className="upload-button">
                              <button
                                onClick={handleUploadLogo}
                                className="btn btn-primary w-200"
                              >
                                Upload Logo
                              </button>
                              <input
                                type="file"
                                id="logoInput"
                                accept="image/*"
                                style={{ display: "none" }}
                                onChange={handleLogoChange}
                              />
                            </div>
                            {logo && (
                              <div className="preview">
                                <img
                                  src={
                                    logo1 ? URL.createObjectURL(logo1) : logo
                                  }
                                  alt="Logo Preview"
                                  className="rounded-circle"
                                />
                                <button
                                  onClick={handleRemoveLogo}
                                  className="btn btn-danger w-200"
                                >
                                  Remove
                                </button>
                              </div>
                            )}
                          </div>
                          <div>
                            <div className="upload-button">
                              <button
                                onClick={handleUploadHeaderPhoto}
                                className="btn btn-primary w-200"
                              >
                                Upload Header Photo
                              </button>
                              <input
                                type="file"
                                id="headerPhotoInput"
                                accept="image/*"
                                style={{ display: "none" }}
                                onChange={handleHeaderPhotoChange}
                              />
                            </div>
                            {headerPhoto && (
                              <div className="preview">
                                <img
                                  src={
                                    headerPhoto1
                                      ? URL.createObjectURL(headerPhoto1)
                                      : headerPhoto
                                  }
                                  alt="Header Photo Preview"
                                  className="rounded-circle"
                                />
                                <button
                                  onClick={handleRemoveHeaderPhoto}
                                  className="btn btn-danger w-200"
                                >
                                  Remove
                                </button>
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                      <form
                        name="basic-info"
                        id="basic-info"
                        className="form-inline"
                        onSubmit={handleSubmit}
                      >
                        <div className="row">
                          <div className="form-group col-md-6 col-sm-12">
                            <label htmlFor="firstNationName">
                              First Nation Name
                            </label>
                            <input
                              id="firstNationName"
                              className="form-control input-group-lg"
                              type="text"
                              name="firstNationName"
                              title="Enter firstNationName"
                              placeholder="First Nation Name"
                              onChange={handleChange}
                              onBlur={handleBlur}
                              value={values.firstNationName}
                            />
                            {errors.firstNationName ? (
                              <span className="text-danger fs-6">
                                {errors.firstNationName}
                              </span>
                            ) : null}
                          </div>
                          <div className="form-group col-md-6 col-sm-12">
                            <label htmlFor="website" className="">
                              Website URL
                            </label>
                            <input
                              id="website"
                              className="form-control input-group-lg"
                              type="text"
                              name="website"
                              title="Enter Website URL"
                              placeholder="Website URL"
                              onChange={handleChange}
                              onBlur={handleBlur}
                              value={values.website}
                            />
                            {errors.website ? (
                              <span className="text-danger fs-6">
                                {errors.website}
                              </span>
                            ) : null}
                          </div>
                        </div>
                        <div className="row">
                          <div className="form-group col-md-6 col-sm-12">
                            <label htmlFor="Mailing Address">
                              Mailing Address
                            </label>
                            <input
                              id="Mailing Address"
                              className="form-control input-group-lg"
                              type="email"
                              name="mailingAddress"
                              title="Enter Mailing Address"
                              placeholder="Mailing Address"
                              onChange={handleChange}
                              onBlur={handleBlur}
                              value={values.mailingAddress}
                            />
                            {errors.mailingAddress ? (
                              <span className="text-danger fs-6">
                                {errors.mailingAddress}
                              </span>
                            ) : null}
                          </div>
                          <div className="form-group col-md-6 col-sm-12">
                            <label htmlFor="city1" className="">
                              City
                            </label>
                            <input
                              id="city1"
                              className="form-control input-group-lg"
                              type="text"
                              name="city1"
                              title="Enter City"
                              placeholder="City"
                              onChange={handleChange}
                              onBlur={handleBlur}
                              value={values.city1}
                            />
                            {errors.city1 ? (
                              <span className="text-danger fs-6">
                                {errors.city1}
                              </span>
                            ) : null}
                          </div>
                        </div>
                        <div className="row">
                          <div className="form-group col-md-6 col-sm-12">
                            <label htmlFor="Province">Province </label>
                            <input
                              id="province"
                              className="form-control input-group-lg"
                              type="text"
                              name="province"
                              title="Enter Province"
                              placeholder="Province"
                              onChange={handleChange}
                              onBlur={handleBlur}
                              value={values.province}
                            />
                            {errors.province ? (
                              <span className="text-danger fs-6">
                                {errors.province}
                              </span>
                            ) : null}
                          </div>
                          <div className="form-group col-md-6 col-sm-12">
                            <label htmlFor="postalCode" className="">
                              Postal Code
                            </label>
                            <input
                              id="postalCode"
                              className="form-control input-group-lg"
                              type="text"
                              name="postalCode"
                              title="Enter Postal Code"
                              placeholder="Postal Code"
                              onChange={handleChange}
                              onBlur={handleBlur}
                              value={values.postalCode}
                            />
                            {errors.postalCode ? (
                              <span className="text-danger fs-6">
                                {errors.postalCode}
                              </span>
                            ) : null}
                          </div>
                        </div>
                        <div className="row">
                          <div className="form-group col-md-6 col-sm-12">
                            <label htmlFor="country1">Country </label>
                            <input
                              id="country1"
                              className="form-control input-group-lg"
                              type="text"
                              name="country1"
                              title="Enter Country "
                              placeholder="Country "
                              onChange={handleChange}
                              onBlur={handleBlur}
                              value={values.country1}
                            />
                            {errors.country1 ? (
                              <span className="text-danger fs-6">
                                {errors.country1}
                              </span>
                            ) : null}
                          </div>
                          <div className="form-group col-md-6 col-sm-12">
                            <label htmlFor="phoneNumber" className="">
                              Phone Number
                            </label>
                            <input
                              id="phoneNumber"
                              className="form-control input-group-lg"
                              type="number"
                              name="phoneNumber"
                              title="Enter Phone Number"
                              placeholder="Phone Number"
                              onChange={handleChange}
                              onBlur={handleBlur}
                              value={values.phoneNumber}
                            />
                            {errors.phoneNumber ? (
                              <span className="text-danger fs-6">
                                {errors.phoneNumber}
                              </span>
                            ) : null}
                          </div>
                        </div>
                        <div className="row">
                          <div className="form-group col-md-6 col-sm-12">
                            <label htmlFor="treaty">Treaty </label>
                            <input
                              id="treaty"
                              className="form-control input-group-lg"
                              type="number"
                              name="treaty"
                              title="Enter Treaty "
                              placeholder="Treaty"
                              onChange={handleChange}
                              onBlur={handleBlur}
                              value={values.treaty}
                            />
                            {errors.treaty ? (
                              <span className="text-danger fs-6">
                                {errors.treaty}
                              </span>
                            ) : null}
                          </div>
                          <div className="form-group col-md-6 col-sm-12">
                            <label htmlFor="tribalCouncil" className="">
                              Tribal
                            </label>
                            <input
                              id="tribalCouncil"
                              className="form-control input-group-lg"
                              type="text"
                              name="tribalCouncil"
                              title="Enter Tribal "
                              placeholder="Tribal "
                              onChange={handleChange}
                              onBlur={handleBlur}
                              value={values.tribalCouncil}
                            />
                            {errors.tribalCouncil ? (
                              <span className="text-danger fs-6">
                                {errors.tribalCouncil}
                              </span>
                            ) : null}
                          </div>
                        </div>
                        <div className="row">
                          <div className="form-group col-md-6 col-sm-12">
                            <label htmlFor="physicalLocation">
                              Physical Location
                            </label>
                            <input
                              id="physicalLocation"
                              className="form-control input-group-lg"
                              type="text"
                              name="physicalLocation"
                              title="Enter Physical Location"
                              placeholder="Physical Location"
                              onChange={handleChange}
                              onBlur={handleBlur}
                              value={values.physicalLocation}
                            />
                            {errors.physicalLocation ? (
                              <span className="text-danger fs-6">
                                {errors.physicalLocation}
                              </span>
                            ) : null}
                          </div>
                          <div className="form-group col-md-6 col-sm-12">
                            <label htmlFor="language" className="">
                              Language
                            </label>
                            <input
                              id="language"
                              className="form-control input-group-lg"
                              type="text"
                              name="language"
                              title="Enter Language "
                              placeholder="Language "
                              onChange={handleChange}
                              onBlur={handleBlur}
                              value={values.language}
                            />
                            {errors.language ? (
                              <span className="text-danger fs-6">
                                {errors.language}
                              </span>
                            ) : null}
                          </div>
                        </div>
                        <div className="row">
                          <div className="form-group col-md-6 col-sm-12">
                            <label htmlFor="onReserveMembership">
                              On-reserve Membership
                            </label>
                            <input
                              id="onReserveMembership"
                              className="form-control input-group-lg"
                              type="number"
                              name="onReserveMembership"
                              title="Enter On-reserve Membership"
                              placeholder="On-reserve Membership"
                              onChange={handleChange}
                              onBlur={handleBlur}
                              value={values.onReserveMembership}
                            />
                            {errors.onReserveMembership ? (
                              <span className="text-danger fs-6">
                                {errors.onReserveMembership}
                              </span>
                            ) : null}
                          </div>
                          <div className="form-group col-md-6 col-sm-12">
                            <label htmlFor="offReserveMembership" className="">
                              Off-reserve Membership
                            </label>
                            <input
                              id="offReserveMembership"
                              className="form-control input-group-lg"
                              type="number"
                              name="offReserveMembership"
                              title="Enter Off-reserve Membership"
                              placeholder="Off-reserve Membership"
                              onChange={handleChange}
                              onBlur={handleBlur}
                              value={values.offReserveMembership}
                            />
                            {errors.offReserveMembership ? (
                              <span className="text-danger fs-6">
                                {errors.offReserveMembership}
                              </span>
                            ) : null}
                          </div>
                        </div>
                        <div className="row">
                          <div className="form-group col-md-6 col-sm-12">
                            <label htmlFor="chief">Chief</label>
                            <input
                              id="chief"
                              className="form-control input-group-lg"
                              type="text"
                              name="chief"
                              title="Enter Chief"
                              placeholder="Chief"
                              onChange={handleChange}
                              onBlur={handleBlur}
                              value={values.chief}
                            />
                            {errors.chief ? (
                              <span className="text-danger fs-6">
                                {errors.chief}
                              </span>
                            ) : null}
                          </div>
                          <div className="form-group col-md-6 col-sm-12">
                            <label htmlFor="councillors" className="">
                              Councillors
                            </label>
                            <input
                              id="councillors"
                              className="form-control input-group-lg"
                              type="text"
                              name="councillors"
                              title="Enter Councillors "
                              placeholder="Councillors "
                              onChange={handleChange}
                              onBlur={handleBlur}
                              value={values.councillors}
                            />
                            {errors.councillors ? (
                              <span className="text-danger fs-6">
                                {errors.councillors}
                              </span>
                            ) : null}
                          </div>
                        </div>
                        <div className="row">
                          <div className="form-group col-md-6 col-sm-12">
                            <label htmlFor="term">Term </label>
                            <input
                              id="term"
                              className="form-control input-group-lg"
                              type="number"
                              name="term"
                              title="Enter Term "
                              placeholder="Term "
                              onChange={handleChange}
                              onBlur={handleBlur}
                              value={values.term}
                            />
                            {errors.term ? (
                              <span className="text-danger fs-6">
                                {errors.term}
                              </span>
                            ) : null}
                          </div>
                          <div className="form-group col-md-6 col-sm-12">
                            <label htmlFor="electionDate" className="">
                              Election Date
                            </label>
                            <input
                              id="electionDate"
                              className="form-control input-group-lg"
                              type="date"
                              name="electionDate"
                              title="Enter Election Date"
                              placeholder="Election Date"
                              onChange={handleChange}
                              onBlur={handleBlur}
                              value={values.electionDate}
                            />
                            {errors.electionDate ? (
                              <span className="text-danger fs-6">
                                {errors.electionDate}
                              </span>
                            ) : null}
                          </div>
                        </div>
                        <div className="row">
                          <div className="form-group col-md-6 col-sm-12">
                            <label htmlFor="economicDevelopmentCorpName">
                              Economic Development Corp Name
                            </label>
                            <input
                              id="economicDevelopmentCorpName"
                              className="form-control input-group-lg"
                              type="text"
                              name="economicDevelopmentCorpName"
                              title="Enter Economic Development Corp Name"
                              placeholder="Economic Development Corp Name"
                              onChange={handleChange}
                              onBlur={handleBlur}
                              value={values.economicDevelopmentCorpName}
                            />
                            {errors.economicDevelopmentCorpName ? (
                              <span className="text-danger fs-6">
                                {errors.economicDevelopmentCorpName}
                              </span>
                            ) : null}
                          </div>
                          <div className="form-group col-md-6 col-sm-12">
                            <label
                              htmlFor="economicDevelopmentCorpWebsite"
                              className=""
                            >
                              Economic Development Corp Website URL
                            </label>
                            <input
                              id="economicDevelopmentCorpWebsite"
                              className="form-control input-group-lg"
                              type="text"
                              name="economicDevelopmentCorpWebsite"
                              title="Enter Economic Development Corp Website URL"
                              placeholder="Economic Development Corp Website URL"
                              onChange={handleChange}
                              onBlur={handleBlur}
                              value={values.economicDevelopmentCorpWebsite}
                            />
                            {errors.economicDevelopmentCorpWebsite ? (
                              <span className="text-danger fs-6">
                                {errors.economicDevelopmentCorpWebsite}
                              </span>
                            ) : null}
                          </div>
                        </div>
                        <div className="row">
                          <div className="form-group col-md-6 col-sm-12">
                            <label htmlFor="existingCompanies">
                              Existing Companies
                            </label>
                            <input
                              id="existingCompanies"
                              className="form-control input-group-lg"
                              type="text"
                              name="existingCompanies"
                              title="Enter Existing Companies"
                              placeholder="Existing Companies"
                              onChange={handleChange}
                              onBlur={handleBlur}
                              value={values.existingCompanies}
                            />
                            {errors.existingCompanies ? (
                              <span className="text-danger fs-6">
                                {errors.existingCompanies}
                              </span>
                            ) : null}
                          </div>
                          <div className="form-group col-md-6 col-sm-12">
                            <label htmlFor="equityInvestments" className="">
                              Equity Investments
                            </label>
                            <input
                              id="equityInvestments"
                              className="form-control input-group-lg"
                              type="text"
                              name="equityInvestments"
                              title="Enter Equity Investments"
                              placeholder="Equity Investments"
                              onChange={handleChange}
                              onBlur={handleBlur}
                              value={values.equityInvestments}
                            />
                            {errors.equityInvestments ? (
                              <span className="text-danger fs-6">
                                {errors.equityInvestments}
                              </span>
                            ) : null}
                          </div>
                        </div>
                        <div className="row">
                          <div className="form-group col-md-6 col-sm-12">
                            <label htmlFor="partners">Partners </label>
                            <input
                              id="partners"
                              className="form-control input-group-lg"
                              type="text"
                              name="partners"
                              title="Enter Partners "
                              placeholder="Partners "
                              onChange={handleChange}
                              onBlur={handleBlur}
                              value={values.partners}
                            />
                            {errors.partners ? (
                              <span className="text-danger fs-6">
                                {errors.partners}
                              </span>
                            ) : null}
                          </div>
                          <div className="form-group col-md-6 col-sm-12">
                            <label htmlFor="news" className="">
                              News
                            </label>
                            <input
                              id="news"
                              className="form-control input-group-lg"
                              type="text"
                              name="news"
                              title="Enter News "
                              placeholder="News "
                              onChange={handleChange}
                              onBlur={handleBlur}
                              value={values.news}
                            />
                            {errors.news ? (
                              <span className="text-danger fs-6">
                                {errors.news}
                              </span>
                            ) : null}
                          </div>
                        </div>
                        <button
                          type="submit"
                          className="btn btn-primary text-center"
                        >
                          {loader ? (
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                              }}
                            >
                              <Loader />
                            </div>
                          ) : (
                            "Save Changes"
                          )}
                        </button>
                      </form>
                    </div>
                  </div>
                </div>
                <div className="col-md-4 static">
                  {/*Sticky Timeline Activity Sidebar*/}
                  <div id="sticky-sidebar">
                    <h4 className="grey">{user?.first_nation} activity</h4>
                    {Activity?.map((e) => {
                      return (
                        <>
                          <div className="feed-item">
                            <div className="live-activity">
                              <p>
                                <a href="#" className="profile-link">
                                  {user?.first_nation}
                                </a>{" "}
                                {e?.description}
                              </p>
                              <p className="text-muted">{moment(e?.created_at).format('hh:mm A')}</p>
                            </div>
                          </div>
                        </>
                      )
                    })}

                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Layout>
    </>
  );
};

export default ProfileOraganization;
