import axios from "axios";

const NewAxiosInstance = axios.create({
  baseURL: 'https://industreeapi.dev-mm.xyz/api/',
});
// 

const token = localStorage.getItem("access_token");
NewAxiosInstance.interceptors.request.use(
  (config) => {
    console.log(token , 'token');
    console.log("Request Interceptor:", config);
    return {
      ...config,
      headers: {
        Authorization: `Bearer ${token}`
      }
    };
  },

  (error) => {
    console.error("Request Interceptor Error:", error);
    return Promise.reject(error);
  }
);

NewAxiosInstance.interceptors.response.use(
  (res) => res,
  (error) =>
    Promise.reject(
      (error.response && error.response.data) || "Something Went Wrong"
    )
);
export default NewAxiosInstance;

// https://docs.google.com/document/d/1MQefrsae8A0OPqqFda69JhU6hDsp6wC0i72h-DRnFxA/edit

