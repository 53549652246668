import Header from "../home/Header";
import Layout from "../layout/Layout";

function Home() {
  return (
    <>
      <Layout>
        <Header />
      </Layout>
    </>
  );
}
export default Home;


