import React from "react";

const Skeleton = () => {
  return (
    <>
      <div className="containers">
        <div className="row d-flex" style={{alignItems:'baseline'}}>
            <div className="lines line1 loading-animation"></div>
            <div className="lines line2 loading-animation"></div>
        </div>
      </div>
    </>
  );
};

export default Skeleton;
